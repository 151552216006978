<div class="page-background-block"></div>
<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-title">Quality Check</div>
  </div>
</div>
<mat-card class="page-content">  
  <div class="table-responsive">
    <table fxFlexFill mat-table [dataSource]="versionInfoDataSource">
      <ng-container matColumnDef="Community">
        <th mat-header-cell *matHeaderCellDef>Community</th>
        <td mat-cell *matCellDef="let item">{{ item.Community }}</td>
      </ng-container>
      <ng-container matColumnDef="Backend">
        <th mat-header-cell *matHeaderCellDef>Backend Version</th>
        <td mat-cell *matCellDef="let item">{{ item.BackendVersion }}</td>
      </ng-container>
      <ng-container matColumnDef="Database">
        <th mat-header-cell *matHeaderCellDef>Database Version</th>
        <td mat-cell *matCellDef="let item">{{ item.DatabaseVersion }}</td>
      </ng-container>
      <ng-container matColumnDef="Compatible">
        <th mat-header-cell *matHeaderCellDef>Compatible</th>
        <td mat-cell *matCellDef="let item">
          <mat-icon *ngIf="item.IsCompatible">check</mat-icon>
          <mat-icon *ngIf="!item.IsCompatible">error_outline</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="Message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let item">{{ item.Message }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="versionInfoColumns"></tr>
      <tr mat-row *matRowDef="let item; columns: versionInfoColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="versionInfoColumns.length">Versie info is momenteel niet beschikbaar.</td>
      </tr>
    </table>
  </div>
</mat-card>
  