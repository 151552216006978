<div *ngIf="niscodeOfficialRoles.IsCommunity; else notCommunity">
  <div fxLayout="column" fxLayoutGap="10px" class="mt-16 ml-16 bb-1 pb-16">
    <div fxLayout="row">
      <strong>{{ niscodeOfficialRoles.Niscode + " - " + niscodeOfficialRoles.NisName }}</strong>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <div fxLayout="column" fxFlex="5%">Ambtenaar:</div>
      <div fxLayout="column" fxFlex="20%">
        <mat-form-field class="w-100">
          <mat-label>Type brief</mat-label>
          <mat-select [(ngModel)]="ambtenaar" [compareWith]="compareObjects" (selectionChange)="updateOfficial($event.value)">
            <mat-option [value]="null">Geen toegang</mat-option>
            <mat-option *ngFor="let option of ambtenaren" [value]="option">{{ option.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row">
      <div fxLayout="column" fxFlex="10%">Rollen:</div>
      <div fxLayout="column" fxFlex="90%">
        <div fxLayout="row" fxFlex="100" *ngFor="let role of niscodeOfficialRoles?.Roles" fxLayoutGap="10px">
          <mat-checkbox [(ngModel)]="role.Selected" [disabled]="ambtenaar === null" (click)="notifyUserOwnRole(role)">
            <div>{{ role.Name }}</div>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notCommunity>
  <div fxLayout="column" fxLayoutGap="10px" class="mt-16 ml-16 bb-1 pb-16">
    <div fxLayout="row">
      <strong>{{ niscodeOfficialRoles.Niscode + " - " + niscodeOfficialRoles.NisName }}</strong>
    </div>
    <div fxLayout="row">
      <mat-checkbox [(ngModel)]="ambtenaar.PersonResponsible" (change)="comunityChanged(niscodeOfficialRoles.IsCommunity)">
        <div>Deze medewerker heeft een overkoepelende rol binnen de IGS.</div>
      </mat-checkbox>
    </div>
  </div>
</ng-template>
