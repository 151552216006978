import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { map } from 'rxjs/operators';
import { Community } from '../models/community.model';
import { environment } from 'src/environments/environment';
import { QualityCheckResult } from '../models/quality-check-result.model';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: 'root'
})
export class QualityService {
  constructor(private http: HttpClient) {

  }

  getQualityCheckOnCommunities(communities: Community[]): Observable<QualityCheckResult[]> {
    let nisCodes = communities.map(community => community.NxCode);
    
    return forkJoin(nisCodes.map(community => this.getQualityCheck(community))).pipe(map(x => x.reduce((array, value) => array.concat(value), [])));
  }

  getQualityCheck(community: string): Observable<QualityCheckResult[]> {   
    let url = environment.api.replace("${api-url-variable}", `${community}.neglect-x.be`);

    // since our local env doesn't use the variable
    if (environment.name === "Development" && !environment.api.includes("localhost")) {
     url = `https://api.${community}.neglect-x.be/api`
    }

    return this.http.get(`${url}/quality/version/${environment.appVersion}`, httpOptions)
      .pipe(map((response: Response) => {        
        response.Data.Community = community;
        
        return response.Data;
    }));
  }
}
