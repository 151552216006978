import { Component, OnInit } from '@angular/core';
import { LoketService } from '../shared/services/loket.service';
import { SettingsService } from '../shared/services/settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-download-center',
  templateUrl: './download-center.component.html',
  styleUrls: ['./download-center.component.scss']
})
export class DownloadCenterComponent implements OnInit {

  constructor(public loketService: LoketService, private settingService: SettingsService, private route: Router) { }

  ngOnInit(): void {
    if (!Boolean(JSON.parse(this.settingService.getSettingByKey("module-eid")?.Value || "0"))) {
      this.route.navigateByUrl('/loket');
    }
  }

  goToExtentionLink(){
    const url = "https://chrome.google.com/webstore/detail/eid-x/pmjcgkekkhobcpbneajeaiiakmpamkpf";
    window.open(url, "_blank");
  }

}
