import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Response } from "../models/response.model";
import { AppState } from "../state/app.state";
import { Logger } from "../helpers/logger";
import { User } from "../models/user.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient, private appState: AppState, private logger: Logger) {
    this.logger.info("UserService - constructor");
  }

  get(): Observable<User> {
    return this.http.get<User>(`${this.appState.baseApiUrl}/user`, httpOptions);
  }
}
