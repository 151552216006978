<div class="page-background-block"></div>
<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-content-title">
      Bedrijfsgegevens <span class="version-label">({{ environment.appVersion }})</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <ng-container *ngIf="authService.hasSuperadmin() | async">
        <button (click)="save()" [disabled]="saving" type="submit" mat-raised-button color="primary">OPSLAAN</button>
      </ng-container>
    </div>
  </div>
</div>
  <mat-card class="page-content">
    <div fxLayout="column" fxLayoutGap="16px">
      <form [formGroup]="form" autocomplete="off" (onSubmit)="save()">
        <div fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="column" fxLayoutGap="16px" *ngIf="this.appState.activeCommunity$.value?.IsCommunity">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0px">
              <mat-card fxFlex="calc(50% - 16px)" class="page-section page-section--without-border mat-elevation-z0">
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutGap="16px">
                    <mat-form-field fxFlex="33%">
                      <mat-label>NIS-code</mat-label>
                      <input matInput formControlName="NisCode" placeholder="NIS-code" required/>
                    </mat-form-field>
                    <mat-form-field fxFlex="33%">
                      <mat-label>Benaming</mat-label>
                      <input matInput formControlName="Name" placeholder="Benaming" required/>
                    </mat-form-field>
                    <mat-form-field fxFlex="33%">
                      <mat-label>Dienst/cel</mat-label>
                      <input matInput formControlName="ServiceCell" placeholder="Dienst/cel" required/>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutGap="16px">
                    <mat-form-field fxFlex="33%">
                      <mat-label>Straat</mat-label>
                      <input matInput formControlName="Street" placeholder="Straat" required/>
                    </mat-form-field>
                    <mat-form-field fxFlex="33%">
                      <mat-label>Huisnummer</mat-label>
                      <input matInput formControlName="HouseNumber" placeholder="Huisnummer" required/>
                    </mat-form-field>
                    <mat-form-field fxFlex="33%">
                      <mat-label>Postcode</mat-label>
                      <input matInput formControlName="PostalCode" placeholder="Postcode" required/>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card>
              <mat-card fxFlex="calc(50% - 16px)" class="page-section page-section--without-border mat-elevation-z0">
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <div fxFlex="33%">
                      <mat-slide-toggle formControlName="HasInventory" color="primary">Beheert inventaris</mat-slide-toggle>
                    </div>
                    <div fxFlex="33%"></div>
                    <div fxFlex="33%"></div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="16px">
                    <mat-form-field fxFlex="33%">
                      <mat-label>Gemeente</mat-label>
                      <input matInput formControlName="City" placeholder="Gemeente" />
                    </mat-form-field>
                    <mat-form-field fxFlex="33%">
                      <mat-label>Telefoon</mat-label>
                      <input matInput formControlName="Telephone" placeholder="Telefoon" />
                    </mat-form-field>
                    <mat-form-field fxFlex="33%">
                      <mat-label>Fax</mat-label>
                      <input matInput formControlName="Fax" placeholder="Fax" />
                    </mat-form-field>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card>
  <mat-card class="page-content" *ngIf="isDebug">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header> user </mat-expansion-panel-header>
        <pre>{{ authService.user | json }}</pre>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header> Authentication Timings </mat-expansion-panel-header>
        <mat-list>
          <mat-list-item>
            <button mat-button click="loadStorageAuthKeys()">load storage</button>
            <button mat-button click="refreshToken()">refresh token</button>
          </mat-list-item>
          <mat-list-item>
            <div class="w-64">user name:</div>
            <div>{{ authService.user.Username }}</div>
          </mat-list-item>
          <mat-list-item *ngIf="authService.claims.nbf">
            <div class="w-64">user nbf (not before):</div>
            <div class="text-sm">{{ authService.user.DateNBF }} ({{ authService.claims.nbf }})</div>
          </mat-list-item>
          <mat-list-item *ngIf="authService.claims.nbf">
            <div class="w-64">user iat (issued iat):</div>
            <div class="text-sm">{{ authService.user.DateIAT }} ({{ authService.claims.iat }})</div>
          </mat-list-item>
          <mat-list-item *ngIf="authService.claims.exp">
            <div class="w-64">user nbf (Expiration Time):</div>
            <div class="text-sm">{{ authService.user.DateEXP }} ({{ authService.claims.exp }})</div>
          </mat-list-item>
          <mat-list-item *ngIf="authService.claims.auth_time">
            <div class="w-64">user auth time (?):</div>
            <div class="text-sm">{{ authService.user.DateAuthTime }} ({{ authService.claims.auth_time }})</div>
          </mat-list-item>
          <!-- -->
          <mat-list-item *ngIf="storageAccessTokenStoredAtEpoc">
            <div class="w-64">access_token_stored_at:</div>
            <div class="text-sm">{{ storageAccessTokenStoredAt }} ({{ storageAccessTokenStoredAtEpoc }})</div>
          </mat-list-item>
          <mat-list-item *ngIf="storageExpiresAtEpoc">
            <div class="w-64">expires_at:</div>
            <div class="text-sm">{{ storageExpiresAt }} ({{ storageExpiresAtEpoc }})</div>
          </mat-list-item>
          <mat-list-item *ngIf="storageIdTokenExpiresAtEpoc">
            <div class="w-64">id_token_expires_at:</div>
            <div class="text-sm">{{ storageIdTokenExpiresAt }} ({{ storageIdTokenExpiresAtEpoc }})</div>
          </mat-list-item>
          <mat-list-item *ngIf="storageidTokenStoredAtEpoc">
            <div class="w-64">id_token_stored_at:</div>
            <div class="text-sm">{{ storageidTokenStoredAt }} ({{ storageidTokenStoredAtEpoc }})</div>
          </mat-list-item>
          <!-- -->
        </mat-list>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header> appState.availableCommunities </mat-expansion-panel-header>
        <pre>{{ appState.availableCommunities$.value | json }}</pre>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header> appState.activeCommunity </mat-expansion-panel-header>
        <pre>{{ appState.activeCommunity$.value | json }}</pre>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>

