<div class="page-background-block"></div>
<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-title">Instellingen</div>
  </div>
</div>
<mat-card class="page-content">
  <div fxLayout="column" fxLayoutGap="16px">
    <div *ngIf="authService.hasSuperadmin() | async" fxLayout="column" fxLayoutGap="0px">
      <div class="table-responsive">
        <mat-table  fxFlexFill [dataSource]="settingsDataSource" class="mat-elevation-z8" matSort (matSortChange)="onSortData($event)">
          <ng-container *ngFor="let column of settingsColumns; let i = index" matColumnDef="{{ column.field }}">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.field }}</mat-header-cell>        
            <mat-cell *matCellDef="let row">
              {{ row[column.field] }}
              <div *ngIf='column.field === "Actions"' fxLayout="row" fxLayoutAlign="start center">
                <button (click)="openSettingsDialog(row)" mat-icon-button matTooltip="Bewerken">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>
          
          <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>

          <!-- Group header cells-->
          <ng-container matColumnDef="groupHeader">
            <td colspan="999" mat-cell *matCellDef="let group">
              <div fxFill fxLayoutAlign="left center">
                <mat-icon *ngIf="!group.reduced">expand_less</mat-icon>
                <mat-icon *ngIf="group.reduced">expand_more</mat-icon>
                <strong>{{group[groupByColumns[group.level-1]]}} ({{group.totalCounts}})</strong>
              </div>
            </td>
          </ng-container>

          <!-- Group line -->
          <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</mat-card>

