import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "src/app/shared/state/app.state";
import { Setting } from "../../shared/models/setting.model";
import { SettingsService } from "../../shared/services/settings.service";
import { Subscription } from "rxjs";
import { UIState } from "../../shared/state/ui.state";
import { AuthService } from "../../auth/auth.service";
import { OnDeactivate, OnDeactivatePayload } from "../../shared/guards/can-deactivate.guard";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { SettingsDialogComponent } from "../../admin/settings/settings-dialog/settings-dialog.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { StorageService } from "../../shared/services/storage.service";
import { MessageService } from "../../shared/services/message.service";
import { Router } from "@angular/router";

export class Group {
	level = 0;
	expanded = false;
	totalCounts = 0;
}

@Component({
  selector: "app-company-details",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public environment: any;
  public isDebug: boolean = false;

  saving: boolean;
  
  settingsColumns: any[];
  displayColumns: string[];
  groupByColumns: string[] = [];
  public settingsDataSource = new MatTableDataSource<any|Group>([]);
  settingSort: any;

  lastUpdated: Date;

  allSettings: any[];
  _allGroup: any[];
	expandedCar: any[] = [];
	expandedSubCar: Setting[] = [];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public appState: AppState,
    public authService: AuthService,
    public uiState: UIState,
    private settingsService: SettingsService,
    private matDialog: MatDialog,
    private localStorageService: StorageService,
    private router: Router
  ) {
    this.environment = environment;
    this.settingsColumns = [{
        field: "Description"
      }, {
        field: "Value"
      }, {
        field: "Type"
      }, {
        field: "Comment"
      }, {
        field: "Group"
      }, {
        field: "Actions"
      }];
      this.displayColumns = this.settingsColumns.map(column => column.field);
		  this.groupByColumns = ['Group'];
  }

  groupHeaderClick(row) {
		if (row.expanded) {
			row.expanded = false;
			this.settingsDataSource = new  MatTableDataSource<Setting>(this.getGroups(this.allSettings, this.groupByColumns));
		} else {
			row.expanded = true;
			this.expandedCar = row;
      this.settingsDataSource = new MatTableDataSource<Setting>(this.addGroupsNew(this._allGroup, this.allSettings, this.groupByColumns, row));
		}
	}

	getGroups(data: any[], groupByColumns: string[]): any[] {
		const rootGroup = new Group();
		rootGroup.expanded = false;
		return this.getGroupList(data, 0, groupByColumns, rootGroup);
	}

	getGroupList(data: any[], level: number = 0, groupByColumns: string[], parent: Group): any[] {
		if (level >= groupByColumns.length) {
			return data;
		}
		let groups = this.uniqueBy(
			data.map(
				row => {
					const result = new Group();
					result.level = level + 1;
					for (let i = 0; i <= level; i++) {
						result[groupByColumns[i]] = row[groupByColumns[i]];
					}
					return result;
				}
			),
			JSON.stringify);

		const currentColumn = groupByColumns[level];
		let subGroups = [];
		groups.forEach(group => {
			const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
			group.totalCounts = rowsInGroup.length;
			this.expandedSubCar = [];
		});
		groups = groups.sort((a: Setting, b: Setting) => {
			const isAsc = 'asc';
			return this.compare(a.Group, b.Group, isAsc);

		});
		this._allGroup = groups;
		return groups;
	}

	addGroupsNew(allGroup: any[], data: any[], groupByColumns: string[], dataRow: any): any[] {
		const rootGroup = new Group();
		rootGroup.expanded = true;
		return this.getSublevelNew(allGroup, data, 0, groupByColumns, rootGroup, dataRow);
	}

	getSublevelNew(allGroup: any[], data: any[], level: number, groupByColumns: string[], parent: Group, dataRow: any): any[] {
		if (level >= groupByColumns.length) {
			return data;
		}
		const currentColumn = groupByColumns[level];
		let subGroups = [];
		allGroup.forEach(group => {
			const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
			group.totalCounts = rowsInGroup.length;
			if (group.Group == dataRow.Group?.toString()) {
				group.expanded = dataRow.expanded;
				const subGroup = this.getSublevelNew(allGroup, rowsInGroup, level + 1, groupByColumns, group, dataRow.Group?.toString());
				this.expandedSubCar = subGroup;
				subGroup.unshift(group);
				subGroups = subGroups.concat(subGroup);
			} else {
				subGroups = subGroups.concat(group);
			}
		});
		return subGroups;
	}

	uniqueBy(a, key) {
		const seen = {};
		return a.filter((item) => {
			const k = key(item);
			return seen.hasOwnProperty(k) ? false : (seen[k] = true);
		});
	}

	isGroup(index, item): boolean {
		return item.level;
	}

	onSortData(sort: MatSort) {
		let data = this.allSettings;
		const index = data.findIndex(x => x['level'] == 1);
		if (sort.active && sort.direction !== '') {
			if (index > -1) {
				data.splice(index, 1);
			}

			data = data.sort((a: Setting, b: Setting) => {
				const isAsc = sort.direction === 'asc';
				switch (sort.active) {
					case 'Description':
						return this.compare(a.Description, b.Description, isAsc);
					case 'Value':
						return this.compare(a.Value, b.Value, isAsc);
          case 'Type':
            return this.compare(a.Type, b.Type, isAsc);
					case 'Comment':
						return this.compare(a.Comment, b.Comment, isAsc);
					default:
						return 0;
				}
			});
		}
		this.settingsDataSource = new MatTableDataSource<Setting>(this.addGroupsNew(this._allGroup, data, this.groupByColumns, this.expandedCar));
	}

	private compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

  ngOnInit(): void {
    this.authService.hasSuperadmin().subscribe((res: boolean) => {
      if (!res) {
        this.router.navigateByUrl("/");
        return;
      }

      this.lastUpdated = new Date(environment.lastUpdated);
      this.uiState.breadcrumbs$.next([
        {
          name: "Instellingen",
        },
      ]);
      
      this.getSettings();
    });
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  save(): void {
    if (this.saving) {
      return;
    }

    this.subscriptions.push(
      this.settingsService.updateClientSettings(this.allSettings).subscribe(
        (settings: Setting[]) => {
          this.saving = false;

          this.getSettings();
        },
        (error: any) => {
          this.saving = false;
        },
      ),
    );
  }

  openSettingsDialog(setting: Setting): void {
    this.matDialog.open(SettingsDialogComponent, {
      width: "780px",
      data: setting,
      disableClose: true,
    });
  }

  getSettings(): void {
    
    this.subscriptions.push(
      this.appState.settings$.subscribe((settings: Setting[]) => {
        this.allSettings = settings;
        this.settingsDataSource  =  new MatTableDataSource<Setting>(this.getGroups(this.allSettings,this.groupByColumns));
      }),
    );
  }

  removeStorage(key: string) {
    localStorage.removeItem(key);
  }

  public refreshToken() {
    console.log(" refreshToken");

    this.authService.refresh().subscribe(
      (res) => {
        console.log("!!!! refreshToken");
        console.log(res);
      },
      (err) => {
        console.error("!!!! refreshToken");

        console.error();
      },
    );
  }

  public storageAccessTokenStoredAtEpoc: number;
  public storageExpiresAtEpoc: number;
  public storageIdTokenExpiresAtEpoc: number;
  public storageidTokenStoredAtEpoc: number;

  public storageAccessTokenStoredAt: Date;
  public storageExpiresAt: Date;
  public storageIdTokenExpiresAt: Date;
  public storageidTokenStoredAt: Date;

  public loadStorageAuthKeys() {
    this.storageAccessTokenStoredAtEpoc = this.localStorageService.get("access_token_stored_at");
    this.storageExpiresAtEpoc = this.localStorageService.get("expires_at");
    this.storageIdTokenExpiresAtEpoc = this.localStorageService.get("id_token_expires_at");
    this.storageidTokenStoredAtEpoc = this.localStorageService.get("id_token_stored_at");

    if (this.storageAccessTokenStoredAtEpoc) {
      this.storageAccessTokenStoredAt = new Date(0); // this way we can set epoch time
      this.storageAccessTokenStoredAt.setUTCMilliseconds(this.storageAccessTokenStoredAtEpoc);
    }
    if (this.storageExpiresAtEpoc) {
      this.storageExpiresAt = new Date(0); // this way we can set epoch time
      this.storageExpiresAt.setUTCMilliseconds(this.storageExpiresAtEpoc);
    }
    if (this.storageIdTokenExpiresAtEpoc) {
      this.storageIdTokenExpiresAt = new Date(0); // this way we can set epoch time
      this.storageIdTokenExpiresAt.setUTCMilliseconds(this.storageIdTokenExpiresAtEpoc);
    }
    if (this.storageidTokenStoredAtEpoc) {
      this.storageidTokenStoredAt = new Date(0); // this way we can set epoch time
      this.storageidTokenStoredAt.setUTCMilliseconds(this.storageidTokenStoredAtEpoc);
    }
  }
}
