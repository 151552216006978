export class Official {
  Code: string;
  Name: string;
  Initials: string;
  IsInitials: boolean;
  Capacity: string;
  AdminCode: string;
  PersonResponsible: boolean;
  LoketResponsible: boolean;
  SignerLeft: boolean;
  SignerMiddle: boolean;
  SignerRight: boolean;
  Signature: string;
  GSM: string;
  Email: string;
  Telephone: string;
  Fax: string;
  SortOrder: number;
  Owner: string;
  TerminalId: string;
  IsHidden: boolean;
}
