export enum DataFilterType {
  NONE = 0,
  BUILDING_LIVING,
  BUILDING_ECONOMIC,
}

export enum OperationType {
  CREATE,
  READ,
  UPDATE,
  DELETE
}

export class Resource {
  Id: number;
  Name: string;

  Type: string;
  Key: string;

  IsReadable: boolean;
  IsCreatable: boolean;
  IsUpdatable: boolean;
  IsDeletable: boolean;
}

export class DataFilter {
  DataFilterType: DataFilterType;

  CanRead: boolean;
  CanWrite: boolean;
}

export class Role {
  Id: number;
  Name: string;
  Niscode: string;
  Resources: Resource[];
  DataFilters: DataFilter[];
  Selected?: boolean;
}

export class UserOfficial {
  Niscode: string;
  OfficialCode: string;
}

export class User {
  Id: number;
  Name: string;
  Email: string;
  IsActive: boolean;
  Niscodes: string[];
  NationalRegister: string;
  OfficialCodes: UserOfficial[];
  Roles: Role[];
  Permissions: string[];
}