<div class="page-background-block"></div>
<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-title">Download center</div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    </div>
  </div>
<mat-card class="page-content" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="16px">
    <mat-card class="example-card">
        <mat-card-header>
          <div mat-card-avatar class="card-header"></div>
          <mat-card-title>eID-X Chrome extension</mat-card-title>
        </mat-card-header>
        <mat-card-actions>
          <button mat-button (click)="goToExtentionLink()">Downloaden</button>
        </mat-card-actions>
    </mat-card>
      <mat-card class="example-card">
        <mat-card-header>
          <div mat-card-avatar class="card-header"></div>
          <mat-card-title>eID-X Reader</mat-card-title>
        </mat-card-header>
        <mat-card-actions>
          Download
          <button mat-button (click)="loketService.downloadEidInstallerMacos()">voor Mac</button>
          <button mat-button (click)="loketService.downloadEidInstallerWindows()">voor Windows</button>
        </mat-card-actions>
      </mat-card>
</mat-card>