import { CommonModule } from "@angular/common";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HasPermissionDirective, ScreenResourceDirective } from "../auth/auth.directives";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { MatModule } from "./mat/mat.module";
import { NgModule } from "@angular/core";
import { NotificationDialogComponent } from "./components/notification-dialog/notification-dialog.component";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { SecureImagePipe } from "./pipes/secure-image.pipe";
import { MessageInterceptor } from "./interceptors/message.interceptor";
import { ReasonRemovalPipe } from "./pipes/reason-removal.pipe";
import { ResponsibleOfficialsPipe } from "./pipes/responsible-officials.pipe";
import { TenantInterceptor } from "./interceptors/tenant.interceptor";
import { ReasonWithoutConsequencesPipe } from "./pipes/reason-without-consequences.pipe";
import { SpinnerDialogComponent } from "./components/spinner-dialog/spinner-dialog.component";
import { CrabAddressPipe } from "./pipes/crab-address.pipe";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgxMaskModule } from "ngx-mask";
import { ReportViewerComponent } from "../reporting/reportviewer/reportviewer.component";


@NgModule({
  declarations: [
    NotificationDialogComponent,
    ConfirmationDialogComponent,
    ReportViewerComponent,
    SpinnerDialogComponent,
    HasPermissionDirective,
    ScreenResourceDirective,
    SecureImagePipe,
    ReasonRemovalPipe,
    ResponsibleOfficialsPipe,
    ReasonWithoutConsequencesPipe,
    CrabAddressPipe
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, MatModule, FlexLayoutModule, NgxMaskModule, NgxMaterialTimepickerModule],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatModule,
    FlexLayoutModule,
    NgxMaskModule,
    // Components
    NotificationDialogComponent,
    ConfirmationDialogComponent,
    ReportViewerComponent,
    SpinnerDialogComponent,
    NgxMaterialTimepickerModule,
    // Directives
    HasPermissionDirective,
    ScreenResourceDirective,
    // Pipes
    SecureImagePipe,
    ReasonRemovalPipe,
    ResponsibleOfficialsPipe,
    ReasonWithoutConsequencesPipe,
    CrabAddressPipe
  ],
  providers: [
    // Order is specific
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MessageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class SharedModule {}
