export const environment = {
  appVersion: require("../../package.json").version + "-demo",
  production: true,
  test: false,
  name: "Demo",
  // api: `${window.location.protocol}//api.${window.location.hostname}/api`,
  // data: `${window.location.protocol}//api.${window.location.hostname}/data`,
  api: "https://demo-api.${api-url-variable}/api",
  data: "https://demo-api.${api-url-variable}/data",
  dataLoket: "https://demo-api.${api-url-variable}/data-loket",
  sts: "https://login.microsoftonline.com/${auth-tenant-id}/v2.0",
  clientId: "",
  lastUpdated: "22/04/2024 12:16",
  appInsightsInstrumentationKey: "30a4910b-bd3c-414e-84cb-ba34bf536720",
  InsightsAppName: "Neglect-X-Demo",
};
