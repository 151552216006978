import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { MessageService } from "./message.service";
import { Observable, Subscription } from "rxjs";
import { Official } from "../models/official.model";
import { tap } from "rxjs/operators";
import { AppState } from "../state/app.state";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../components/notification-dialog/notification-dialog.component";
import { FileStorageFile } from "../models/file-storage-file.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class OfficialService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private http: HttpClient,
    private appState: AppState,
    private messageService: MessageService,
    private matDialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getList(): Observable<Official[]> {
    return this.http.get<Official[]>(`${this.appState.baseApiUrl}/officials`, httpOptions).pipe(
      tap((officials: Official[]) => {
        this.appState.officials$.next(officials);

        if (officials.map((x) => x.PersonResponsible).length === 0) {
          this.matDialog.open(NotificationDialogComponent, {
            width: "550px",
            data: {
              title: `Waarschuwing`,
              text: `Er dient minstens één ambtenaar als dossierbeheerde ingesteld te zijn.`,
            },
          });
        }
      }),
    );
  }

  getDetail(code: string): Observable<Official> {
    return this.http.get<Official>(`${this.appState.baseApiUrl}/officials/${code}`, httpOptions);
  }

  create(official: Official): Observable<Official> {
    return this.http.post<Official>(`${this.appState.baseApiUrl}/officials`, official, httpOptions).pipe(
      tap((officialReturned: Official) => {
        this.messageService.showSnackBarInfo(`Ambtenaar ${officialReturned.Code} opgeslagen.`);
      }),
    );
  }

  update(official: Official): Observable<Official> {
    return this.http.put<Official>(`${this.appState.baseApiUrl}/officials/${official.Code}`, official, httpOptions).pipe(
      tap((officialReturned: Official) => {
        this.messageService.showSnackBarInfo(`Ambtenaar ${officialReturned.Code} opgeslagen.`);
      }),
    );
  }

  delete(code: string): Observable<any> {
    return this.http
      .delete(`${this.appState.baseApiUrl}/officials/${code}`, httpOptions)
      .pipe(tap(() => this.messageService.showSnackBarInfo(`Ambtenaar ${code} verwijderd.`)));
  }

  saveSignaturePhoto(code: string, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/officials/signature/${code}`, file);
  }

  getSignaturePhoto(code: string): Observable<FileStorageFile> {
    return this.http.get<FileStorageFile>(`${this.appState.baseApiUrl}/officials/signature/${code}`);
  }

  deleteSignaturePhoto(code: string): Observable<any> {
    return this.http.delete(`${this.appState.baseApiUrl}/officials/signature/${code}`);
  }
}
