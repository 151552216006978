import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DocumentationService } from '../shared/services/documentation.service';
import { UIState } from '../shared/state/ui.state';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  
  public documentation: string;

  constructor(
    private uiState: UIState, 
    private route: ActivatedRoute,
    private documentationService: DocumentationService
  ) { }

  ngOnInit(): void {
    this.uiState.breadcrumbs$.next([{
      name: "Documentatie"
    }]);

    this.subscriptions.push(this.documentationService.getDocumentation().subscribe(str => {
      this.documentation = str;

      // this has to be delayed to give markdown time to render
      let delayedAction = setInterval(() => {
        this.loadImages();

        const section = this.route.snapshot.paramMap.get("section");
        if (section?.length == 0) {
          return;
        }

        this.performScroll(section);
        clearInterval(delayedAction);
      }, 300);
    }));
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
    
  loadImages(): void {
    for (const element of document.getElementsByTagName("img")) {
      const filenameAttribute = element.getAttribute("filename");
      if (filenameAttribute === null) {
        continue;
      }

      this.subscriptions.push(this.documentationService.getImage(filenameAttribute).subscribe((image: Blob) => {
        this.createImageFromBlob(element, image);
      }));
    }
  }

  createImageFromBlob(imageElement: HTMLImageElement, image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      imageElement.src = reader.result as string;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
  }

  // -----------------------------------------------------------------------------------------------
  // automatic scrolling
  @HostListener("document:click", ["$event"])
  onDocumentClicked(event: Event): void {
    if (event.target instanceof HTMLButtonElement || event.target instanceof HTMLAnchorElement) {
      this.performElementScroll(event.target);
    } else if ((event.target as HTMLSpanElement).parentElement instanceof HTMLButtonElement) {
      this.performElementScroll((event.target as HTMLSpanElement).parentElement);
    }
  }

  performElementScroll(element: HTMLElement): void {
    const navAttribute = element.getAttribute("navigateTo");
    if (navAttribute === null) {
      return;
    }

    this.performScroll(navAttribute);
  }

  performScroll(id: string): void {
    const targetElement = document.getElementById(id);
    if (targetElement === null) {
      return;
    }

    targetElement.scrollIntoView();
  }
  // -----------------------------------------------------------------------------------------------
}
