import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { QualityCheckResult } from 'src/app/shared/models/quality-check-result.model';
import { QualityService } from 'src/app/shared/services/quality.service';
import { AppState } from 'src/app/shared/state/app.state';
import { UIState } from 'src/app/shared/state/ui.state';

@Component({
  selector: 'app-quality-check',
  templateUrl: './quality-check.component.html',
  styleUrls: ['./quality-check.component.scss']
})
export class QualityCheckComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  versionInfoColumns: string[] = [ "Community", "Backend", "Database", "Compatible", "Message" ];
  versionInfoDataSource: MatTableDataSource<QualityCheckResult>;

  constructor(
    private qualityService: QualityService,
    private uiState: UIState,
    private appState: AppState,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.hasSuperadmin().subscribe((res: boolean) => {
      if (!res) {
        this.router.navigateByUrl("/");
        return;
      }

      this.uiState.breadcrumbs$.next([{
        name: "Quality Check"
      }]);

      this.getQualityChecks();
    });
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getQualityChecks(): void {
    this.subscriptions.push(
      this.appState.availableCommunities$.subscribe(communities => {
        if (communities.length == 0) {
          return;
        }

        this.subscriptions.push(this.qualityService.getQualityCheckOnCommunities(communities).subscribe((appProxies: QualityCheckResult[]) => {
          this.versionInfoDataSource = new MatTableDataSource<QualityCheckResult>(appProxies);
        }))
      }
    ));
  }
}
