<mat-sidenav-container fxFlexFill autosize>
  <mat-sidenav
    [opened]="uiState.showSideNav$.value"
    (openedChange)="uiState.showSideNav$.next($event)"
    [mode]="uiState.sideNavModeMediaQuery.matches ? 'over' : 'side'"
    [class.mat-elevation-z8]="uiState.showSideNav$.value"
  >
    <div fxLayout="column" fxLayoutAlign="space-between">
      <div fxFlex="64px">
        <mat-toolbar color="primary">
          <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
            <div>Hoofdmenu</div>
            <button (click)="uiState.toggleSideNav()" mat-icon-button>
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
        </mat-toolbar>
      </div>
      <div class="sidenav__content" fxFlex="1 1 calc(100vh - 64px)" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="24px">
        <mat-nav-list>
          <div class="sidenav__subheader">Inventaris</div>
          <mat-list-item 
            *ngIf="this.appState.activeCommunity$.value.IsCommunity" 
            (click)="uiState.toggleSideNav()" 
            routerLink="/dossiers"
            routerLinkActive="active">
            Dossiers
          </mat-list-item>
          <mat-list-item
            *ngIf="this.appState.activeCommunity$.value.IsCommunity"
            (click)="uiState.toggleSideNav()"
            routerLink="/eigenaars"
            routerLinkActive="active">
            Eigenaars
          </mat-list-item>
        <mat-list-item *screenResource="['REPORTS', 'READ']" (click)="uiState.toggleSideNav()" routerLink="/rapportering" routerLinkActive="active" [queryParams]="{type: 'inventaris'}">
          Rapportering
        </mat-list-item>
        <mat-nav-list>
          <mat-list-item (click)="uiState.toggleSideNav()" *screenResource="['EXPORTOVERZICHT', 'READ']" routerLink="/export-overzicht" routerLinkActive="active"
            >Overzichten</mat-list-item
          >
        </mat-nav-list>
          <ng-container *ngIf="this.appState.activeCommunity$.value.IsCommunity">
            <mat-list-item *screenResource="['REPORTS', 'READ']" (click)="uiState.toggleSideNav()" routerLink="/werkopdrachten" routerLinkActive="active"
              [queryParams]="{type: 'inventaris'}">
              Werkopdrachten
            </mat-list-item>
          </ng-container>
          <ng-container *ngIf="this.appState.activeCommunity$.value.IsCommunity">
            <mat-list-item *screenResource="['FINANCIEELDIENST', 'READ']" (click)="uiState.toggleSideNav()" routerLink="/export-financiele-dienst" routerLinkActive="active">
              Financiële dienst
            </mat-list-item>
          </ng-container>  
          <ng-container *screenResource="['VERMOEDENSLIJSTIMPORT', 'READ']">
            <mat-list-item
              *ngIf="this.appState.activeCommunity$.value.IsCommunity && moduleVermoedensLijst"
              (click)="uiState.toggleSideNav()"
              routerLink="/vermoedenslijst"
              routerLinkActive="active">
              Vermoedenslijst
            </mat-list-item>
          </ng-container>
          <ng-container *screenResource="['SHAREPOINT', 'READ']">
            <mat-list-item *ngIf="rootFolderLink" (click)="navigateRootDrive()">
              Naar Sharepoint
            </mat-list-item>
          </ng-container>
          <!-- <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/bulk-generation" routerLinkActive="active">
            Bulkgenereren
          </mat-list-item> -->
        </mat-nav-list>
        <!-- <div *ngIf="moduleCadastral">
          <div class="sidenav__subheader">Import</div>
          <mat-nav-list>
            <mat-list-item>Kadastrale gegevens</mat-list-item>
          </mat-nav-list>
        </div> -->
        <ng-container *screenResource="['LOKET', 'READ']">
          <div *ngIf="moduleLoket && this.appState.activeCommunity$.value.IsCommunity">
            <div class="sidenav__subheader">Loket</div>
            <mat-nav-list>
              <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/loket"  [routerLinkActiveOptions]="{ exact: true }" 
                routerLinkActive="active">Loketdossiers</mat-list-item>
              <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/loket/partners" routerLinkActive="active">Partners</mat-list-item>
              <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/loket/activiteiten-template" routerLinkActive="active">
                Activiteiten
              </mat-list-item>
              <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/rapportering" routerLinkActive="active"
              [queryParams]="{type: 'loket'}">
                Rapportering
              </mat-list-item>
              <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/werkopdrachten" routerLinkActive="active"
              [queryParams]="{type: 'loket'}">
                Werkopdrachten
              </mat-list-item>
              <mat-list-item *ngIf="moduleEid" (click)="uiState.toggleSideNav()" routerLink="/download-center" routerLinkActive="active">
                Download center
              </mat-list-item>
              <mat-list-item *ngIf="rootFolderLinkLoket" (click)="navigateRootDriveLoket()">
                Naar Sharepoint
              </mat-list-item>
            </mat-nav-list>
          </div>
        </ng-container>
        <div *ngIf="moduleVLOK">
          <div class="sidenav__subheader">Koppelingen</div>
          <mat-nav-list>
            <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/vlok" routerLinkActive="active">VLOK</mat-list-item>
          </mat-nav-list>
        </div>
        
        <div>
          <div class="sidenav__subheader">Instellingen</div>          
          <mat-nav-list>
            <ng-container *screenResource="['AMBTENAARBEHEER', 'READ']">
              <mat-list-item
                *ngIf="this.appState.activeCommunity$.value.IsCommunity"
                (click)="uiState.toggleSideNav()"
                routerLink="/ambtenaren"
                routerLinkActive="active">
                Ambtenaren
              </mat-list-item>
            </ng-container>
            <ng-container *screenResource="['BEDRIJFSGEGEVENS', 'READ']">
            <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/instellingen/bedrijfsgegevens" routerLinkActive="active">
              Bedrijfsgegevens
            </mat-list-item>
            </ng-container>
            <ng-container *screenResource="['BRIEVENBEHEER', 'READ']">
              <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/instellingen/brievenbeheer" routerLinkActive="active">
                Brievenbeheer
              </mat-list-item>
            </ng-container>
            <ng-container *screenResource="['VLOK_IMPORT', 'READ']">
              <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/instellingen/vlok-import" routerLinkActive="active">
                VLOK Import
              </mat-list-item>
            </ng-container>
          </mat-nav-list>
        </div>
        
        <div *ngIf="authService.hasSuperadmin() | async">
          <div class="sidenav__subheader">Administrator</div>
          <mat-nav-list>
            <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/admin/quality-check" routerLinkActive="active">
              Quality Check
            </mat-list-item>
            <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/admin/beheersrechten" routerLinkActive="active">
              Gebruikersbeheer
            </mat-list-item>
            <mat-list-item (click)="uiState.toggleSideNav()" routerLink="/admin/instellingen" routerLinkActive="active">
              Instellingen
            </mat-list-item>
            
          </mat-nav-list>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="top mat-elevation-z4" color="primary">
      <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <button (click)="uiState.toggleSideNav()" mat-icon-button>
            <mat-icon>menu</mat-icon>
          </button>
          <div routerLink="/" class="clickable" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <img class="logo" src="../../assets/logos/Neglect-X_Logo_128px.svg" alt="Neglect-X Logo" />
          </div>
          <div class="breadcrumbs" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
              <div class="breadcrumb" *ngIf="!breadcrumb.routerLink">{{ breadcrumb.name }}</div>
              <div class="breadcrumb clickable" *ngIf="breadcrumb.routerLink" [routerLink]="breadcrumb.routerLink">
                {{ breadcrumb.name }}
              </div>
              <mat-icon *ngIf="i !== breadcrumbs.length - 1">chevron_right</mat-icon>
            </ng-container>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div>
              {{ appState.activeCommunity$.value?.Name }}
            </div>
            <div *ngIf="appState.availableCommunities$.value.length > 1">
              <button mat-icon-button [matMenuTriggerFor]="communityMenu" [disabled]="!appState.allowedToChangeCommunity">
                <mat-icon>expand_more</mat-icon>
              </button>
              <mat-menu #communityMenu="matMenu">
                <ng-container *ngFor="let item of appState.availableCommunities$.value">
                  <button mat-menu-item (click)="changeCommunity(item)">
                    <mat-icon *ngIf="item.IsCommunity">location_city</mat-icon>
                    <mat-icon *ngIf="!item.IsCommunity">stars</mat-icon>
                    <span>{{ item?.Name }}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
          <div fxHide.lt-md>
            {{ authService.user?.Name }}
          </div>
          <div>
            <button mat-icon-button [routerLink]="['/documentation', { section: 'PLACEHOLDER' }]"><mat-icon>help_outline</mat-icon></button>
          </div>
          <div>
            <a href="https://neglect-x.freshservice.com/support/home" target="_blank" rel="noopener noreferrer">
              <button mat-icon-button matTooltip="Support">
                <mat-icon>headset_mic</mat-icon>
              </button>
            </a>
          </div>          
          <div fxLayout="row" fxLayoutGap="8px">
            <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="this.appState.activeCommunity$.value?.IsCommunity"><mat-icon>bookmarks</mat-icon></button>
            <mat-menu class="rowmenu" #menu="matMenu">
              <div class="mat-title">Bewerkt</div>
              <div fxLayout="row">
                <div fxLayout="column">
                  <div class="mat-subheading-2">Inventaris</div>
                  <ng-container *ngFor="let lastTouchedDossier of appState.lastTouchedDossiers$ | async">
                    <button mat-menu-item [routerLink]="['dossiers/', lastTouchedDossier.Code]">
                      {{ lastTouchedDossier.Code }} - {{ lastTouchedDossier.Street }} {{ lastTouchedDossier.HouseNumber }}
                      {{ lastTouchedDossier.BoxNumber }}
                    </button>
                  </ng-container>
                </div>
                <ng-container *screenResource="['LOKET', 'READ']">
                  <div fxLayout="column" *ngIf="moduleLoket">
                    <div class="mat-subheading-2">Loket</div>
                    <ng-container *ngFor="let dossier of appState.lastTouchedLoketDossiers$ | async">
                      <button mat-menu-item [routerLink]="['loket/', dossier.Id]">
                        {{ dossier.Code }} - {{ dossier.Street }} {{ dossier.HouseNumber }} {{ dossier.BoxNumber }}
                      </button>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
              <hr />
              <div class="mat-title">Geraadpleegd</div>
              <div fxLayout="row">
                <div fxLayout="column">
                  <div class="mat-subheading-2">Inventaris</div>
                  <ng-container *ngFor="let dossier of appState.lastVisitDossiers$ | async">
                    <button mat-menu-item [routerLink]="['dossiers/', dossier.Code]">
                      {{ dossier.Code }} - {{ dossier.Street }} {{ dossier.HouseNumber }} {{ dossier.BoxNumber }}
                    </button>
                  </ng-container>
                </div>
                <ng-container *screenResource="['LOKET', 'READ']">
                  <div fxLayout="column" *ngIf="moduleLoket">
                    <div class="mat-subheading-2">Loket</div>
                    <ng-container *ngFor="let dossier of appState.lastVisitLoketDossiers$ | async">
                      <button mat-menu-item [routerLink]="['loket/', dossier.Id]">
                        {{ dossier.Code }} - {{ dossier.Street }} {{ dossier.HouseNumber }} {{ dossier.BoxNumber }}
                      </button>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </mat-menu>
            <button mat-icon-button (click)="logout()"><mat-icon>logout</mat-icon></button>
          </div>
        </div>
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
    <div fxLayout="row" fxLayoutAlign="center center" class="footer mat-elevation-z4">
      Neglect-X v{{ environment.appVersion }} © Neglect-X bv{{ footerText?.length <= 0 ? "" : " - " + footerText }}
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>