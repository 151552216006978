<div class="dialog">
  <button class="dialog-close" type="button" mat-mini-fab color="primary" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="mat-headline">
          <span>Instelling {{ setting.Key }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <button type="submit" [disabled]="saving" mat-raised-button color="primary">OPSLAAN</button>
        </div>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-label>Gevolg</mat-label>
          <input matInput placeholder="Key" formControlName="Key" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-label>Key</mat-label>
          <input matInput formControlName="Key" placeholder="Key" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-label>Description</mat-label>
          <input matInput formControlName="Description" placeholder="Description" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-label>Value</mat-label>
          <input matInput formControlName="Value" placeholder="Value" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-label>Type</mat-label>
          <input matInput formControlName="Type" placeholder="Type" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-label>Comment</mat-label>
          <input matInput formControlName="Comment" placeholder="Comment" />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
