import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Setting } from "../models/setting.model";
import { map } from "rxjs/operators";
import { Response } from "../models/response.model";
import { AppState } from "../state/app.state";
import { Community } from "../models/community.model";
import { Logger } from "../helpers/logger";
import { environment } from "src/environments/environment";
import { SettingsGebType } from "../models/settings-geb-type.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  constructor(private http: HttpClient, private appState: AppState, private logger: Logger) {
    this.logger.info("SettingsService - constructor");
  }

  get(): Observable<Setting[]> {
    return this.http.get<Response>(`${this.appState.baseApiUrl}/settings`, httpOptions).pipe(
      map((response: Response) => {
        if (!response.Success) return;
        this.appState.settings$.next(response.Data);
        this.appState.moduleLeegstand$.next(Boolean(JSON.parse(this.getSettingByKey("module-verwaarlozing").Value || "0")));
        this.appState.moduleVerwaarlozing$.next(Boolean(JSON.parse(this.getSettingByKey("module-verwaarlozing").Value || "0")));
        this.appState.moduleVLOK$.next(Boolean(JSON.parse(this.getSettingByKey("module-vlok").Value || "0")));
        this.appState.moduleWoningkwaliteit$.next(Boolean(JSON.parse(this.getSettingByKey("module-ongeschikt-onbewoonbaar").Value || "0")));
        return response.Data;
      }),
    );
  }

  getGeb(): Observable<SettingsGebType[]> {
    return this.http.get<Response>(`${this.appState.baseApiUrl}/settings/geb`, httpOptions).pipe(
      map((response: Response) => {
        if (!response.Success) return;
        this.appState.settingsGebType$.next(response.Data);
        return response.Data;
      }),
    );
  }

  getSettingByKey(key: string): Setting {
    if (!key) return null;

    const setting = this.appState.settings.find((x) => x.Key === key);

    if (!setting) return null;

    return setting;
  }

  getGebSettingByGebouwAndTvInVType(gebouwType: string, tvInvType: string): SettingsGebType {
    if (!gebouwType || !tvInvType) return null;
    this.logger.info(gebouwType);
    this.logger.info(tvInvType);

    const setting = this.appState.settingsGebType.find((x) => x.GebouwType === gebouwType && x.TvInvType === tvInvType && this.todayBetweenPeriod(x.StartDate, x.EndDate));

    if (!setting) return null;
    //It is possible in very specific cases that settingsGebType is not declared yet when retrieving.
    //This only occurs very rarely and is due to javascript racing condition.
    return setting;
  }

  todayBetweenPeriod(startDateString: string, endDateString: string): boolean{
    //checks if today falls between 2 dates
    var today = new Date();
    var startDate = new Date(startDateString)
    var endDate = new Date(endDateString)
    
    if(endDate <= new Date(0)){
      //if the endDate is not given it should always be true
      endDate = today
    }
    this.logger.info(startDate, today, endDate)

    if(startDate <= today && (endDate >= today)){
      return true
    }
    return false
  }

  updateClientSettings(settings: Setting[]): Observable<Setting[]> {
    return this.http.put<Response>(`${this.appState.baseApiUrl}/settings/client`, settings, httpOptions).pipe(
      map((response: Response) => {
        if (!response.Success) return;
        this.get();
        return response.Data;
      }),
    );
  }

  update(settings: Setting): Observable<Setting> {
    return this.http.put<Response>(`${this.appState.baseApiUrl}/settings`, settings, httpOptions).pipe(
      map((response: Response) => {
        if (!response.Success) return;
        this.get();
        return response.Data;
      }),
    );
  }

  getCommunities(): Observable<Community[]> {
    const url = environment.api.replace("${api-url-variable}", window.location.hostname);
    this.logger.info("SettingService - getCommunities ", url);

    return this.http.get<Response>(`${url}/settings/multi-community`, httpOptions).pipe(
      map((response: Response) => {
        if (!response.Success) return;
        this.appState.communities$.next(response.Data);
        this.logger.info("SettingService - getCommunities ", this.appState.communities$.value);

        return response.Data;
      }),
    );
  }

  getCommunityName(nxCode: string): string {
    const communities = this.appState.communities$.value;
    const community = communities.find((x) => x.NxCode?.toLowerCase() === nxCode?.toLowerCase());
    return community?.Name ?? "";
  }

  getRootFolder(){
    return this.http.get<Response>(`${this.appState.baseApiUrl}/settings/root-drive`, httpOptions).pipe(
      map((response: Response) => {
        if (!response.Success) return;
        return response.Data;
      }),
    );
  }

  getRootFolderLoket(){
    return this.http.get<Response>(`${this.appState.baseApiUrl}/settings/root-drive-loket`, httpOptions).pipe(
      map((response: Response) => {
        if (!response.Success) return;
        return response.Data;
      }),
    );
  }
}
