import { AdministrativeAct } from "../shared/models/administrative-act.model";
import { AppState } from "../shared/state/app.state";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { CadastralLocation } from "../shared/models/cadastral-locations.model";
import { Contact } from "../shared/models/contact.model";
import { Dossier } from "../shared/models/dossier.model";
import { DossierOwner } from "../shared/models/owner.model";
import { Injectable, OnDestroy } from "@angular/core";
import { Logger } from "../shared/helpers/logger";
import { Official } from "../shared/models/official.model";
import { BuildingRVVRecipient } from "../shared/models/rvv-recipient.model";
import { VlokResponse } from "../shared/models/vlok.model";

@Injectable({
  providedIn: "root",
})
export class DossierState implements OnDestroy {
  private subscriptions: Subscription[] = [];

  listFilterString$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  dossiers$: BehaviorSubject<Dossier[]> = new BehaviorSubject<Dossier[]>([]);
  selectedDossier$: BehaviorSubject<Dossier> = new BehaviorSubject<Dossier>(null);
  dossierAdministrators$: BehaviorSubject<Official[]> = new BehaviorSubject<Official[]>([]);
  casingDossiers$: BehaviorSubject<Dossier[]> = new BehaviorSubject<Dossier[]>([]);

  dossierPhotoUpdated$: Subject<void> = new Subject();
  dossierFileAdded$: Subject<void> = new Subject();

  entities$: BehaviorSubject<Dossier[]> = new BehaviorSubject<Dossier[]>([]);
  rightInRemOwners$: BehaviorSubject<DossierOwner[]> = new BehaviorSubject<DossierOwner[]>([]);
  cadastralLocations$: BehaviorSubject<CadastralLocation[]> = new BehaviorSubject<CadastralLocation[]>([]);
  unoccupiedAdministrativeActs$: BehaviorSubject<AdministrativeAct[]> = new BehaviorSubject<AdministrativeAct[]>([]);
  neglectedAdministrativeActs$: BehaviorSubject<AdministrativeAct[]> = new BehaviorSubject<AdministrativeAct[]>([]);
  contacts$: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([]);
  rvvRecipients$: BehaviorSubject<BuildingRVVRecipient[]> = new BehaviorSubject<BuildingRVVRecipient[]>([]);

  vlokInformation$: BehaviorSubject<VlokResponse> = new BehaviorSubject<VlokResponse>(null);

  SuspectDossier$: BehaviorSubject<Dossier> = new BehaviorSubject<Dossier>(null);

  constructor(private appState: AppState, private logger: Logger) {
    this.subscriptions.push(
      this.appState.officials$.subscribe((officials: Official[]) => {
        this.dossierAdministrators$.next(officials.filter((o) => o.PersonResponsible));
      }),
    );

    this.subscriptions.push(
      this.selectedDossier$.subscribe((dossier: Dossier) => {
        this.logger.log("dossier", dossier);

        if (!dossier) {
          this.entities$.next([]);
          this.rightInRemOwners$.next([]);
          this.cadastralLocations$.next([]);
          this.unoccupiedAdministrativeActs$.next([]);
          this.contacts$.next([]);
          this.rvvRecipients$.next([]);
          this.vlokInformation$.next(null);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
