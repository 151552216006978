import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Logger } from '../shared/helpers/logger';
import { Activity } from '../shared/models/activity.model';
import { ContactPerson } from '../shared/models/contact-person.model';
import { Dossier } from '../shared/models/dossier.model';
import { LoketDossier } from '../shared/models/loket-dossier.model';
import { Official } from '../shared/models/official.model';
import { AppState } from '../shared/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class LoketState implements OnDestroy {
  private subscriptions: Subscription[] = [];

  loketOfficials$: BehaviorSubject<Official[]> = new BehaviorSubject<Official[]>([]);
  currentDossier$: BehaviorSubject<LoketDossier> = new BehaviorSubject<LoketDossier>(null);
  linkableDossier$: BehaviorSubject<Dossier> = new BehaviorSubject<Dossier>(null);
  currentMainContactPerson$: BehaviorSubject<ContactPerson> = new BehaviorSubject<ContactPerson>(null);
  currentDossierContactPersons$: BehaviorSubject<ContactPerson[]> = new BehaviorSubject<ContactPerson[]>(null);
  activities$: BehaviorSubject<Activity[]> = new BehaviorSubject<Activity[]>([]);

  constructor(private appState: AppState, private logger: Logger) {
    this.subscriptions.push(
      this.appState.officials$.subscribe((officials: Official[]) => {
        this.loketOfficials$.next(officials.filter((o) => o.LoketResponsible));
      }),
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  
}
