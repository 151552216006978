<div fxFill fxLayout="row" fxLayoutAlign="space-between">
  <div fxHide.lt-md class="left-section" fxFlex="1 1" fxLayout="column" fxLayoutAlign="end center">
    <div class="branding" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="24px">
      <img src="assets/logos/Neglect-X_Logo_1000.png" width="128" height="128" alt="Neglect-X Logo" />
      <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
        <div class="title bold">Neglect-X</div>
        <div class="subtitle">Onze oplossing voor leegstaande, verwaarloosde en verkrotte woningen…</div>
      </div>
    </div>
  </div>
  <div class="right-section" fxFlex="1 0 300px" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
      <img fxHide.gt-sm src="assets/logos/Neglect-X_Logo_1000.png" width="128" height="128" alt="Neglect-X Logo" />
      <div class="title">Welkom bij Neglect-X</div>
    </div>
    <!--<form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
      <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px">
        <mat-form-field>
          <mat-label>E-mailadres</mat-label>
          <input matInput formControlName="Email" autocomplete="email" />
          <mat-error *ngIf="loginForm.get('Email').hasError('required')">E-mailadres is verplicht</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Wachtwoord</mat-label>
          <input matInput type="password" formControlName="Password" autocomplete="current-password" />
          <mat-error *ngIf="loginForm.get('Password').hasError('required')">Wachtwoord is verplicht</mat-error>
        </mat-form-field>
        <div *ngIf="errorMessage" class="error" fxLayout="row" fxLayoutAlign="center start">
          {{ errorMessage }}
        </div>
        <button disabled mat-raised-button type="submit" color="primary">AANMELDEN</button>
        <button disabled mat-raised-button type="submit" color="primary" [disabled]="!loginForm.valid">AANMELDEN</button>
      </div>
    </form>-->
    <div fxLayout="column" fxLayoutGap="24px">
      <hr />
      <button (click)="loginWithSSO()" mat-raised-button type="button" color="primary">AANMELDEN MET MICROSOFT</button>
      <button *ngIf="!environment.production" (click)="logoutWithSSO()" mat-raised-button type="button" color="primary">DEBUG LOGOUT</button>
      <!--<div fxLayout="row" fxLayoutAlign="center start">
        <a [href]="ssoLink" target="_blank">MIJN ACCOUNT</a>
      </div>-->
    </div>
  </div>
</div>
<span class="version-label"> {{ environment.appVersion }} </span>
