import { AuthLoginComponent } from "./auth/login/login.component";
import { AuthSSOCallbackComponent } from "./auth/sso-callback/sso-callback.component";
import { CanDeactivateGuard } from "./shared/guards/can-deactivate.guard";
import { HomeComponent } from "./home/home.component";
import { IsAuthenticatedGuard, PermissionGuard } from "./auth/auth.guards";
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CompanyDetailsComponent } from "./settings/company-details/company-details.component";
import { IsMulticommunityGuard } from "./shared/guards/multicommunity.guards";
import { QualityCheckComponent } from "./admin/quality-check/quality-check.component";
import { DocumentationComponent } from "./documentation/documentation.component";
import { OpenDossierComponent } from "./open/open.component";
import { BeheersrechtenComponent } from "./admin/beheersrechten/beheersrechten.component";
import { SettingsComponent } from "./admin/settings/settings.component";
import { ExportOverviewComponent } from "./export-overview/export-overview.component";
import { DownloadCenterComponent } from "./download-center/download-center.component";
import { VlokImportComponent } from "./settings/vlok-import/vlok-import.component";

const routes: Routes = [
  { path: "dev", loadChildren: () => import("./dev/dev.module").then((m) => m.DevModule) },
  { path: "login", component: AuthLoginComponent },
  { path: "auth/sso-callback", component: AuthSSOCallbackComponent },
  {
    path: "",
    component: HomeComponent,
    canActivate: [IsAuthenticatedGuard, PermissionGuard],
    data: {
      permissions: ["nx.read"],
    },
    children: [
      { path: "open/:code", component: OpenDossierComponent },
      { path: "admin/quality-check", component: QualityCheckComponent },
      { path: "admin/beheersrechten", component: BeheersrechtenComponent },
      { path: "admin/instellingen", component: SettingsComponent },
      { path: "documentation", component: DocumentationComponent },
      {
        path: "dossiers/nieuw",
        loadChildren: () => import("./dossiers/dossier-create/dossier-create.module").then((m) => m.DossierCreateModule),
        canActivateChild: [IsMulticommunityGuard],
      },
      {
        path: "dossiers/:dossierCode",
        loadChildren: () => import("./dossiers/dossier-detail/dossier-detail.module").then((m) => m.DossierDetailModule),
      },
      {
        path: "dossiers",
        loadChildren: () => import("./dossiers/dossier-list/dossier-list.module").then((m) => m.DossierListModule),
        canActivateChild: [IsMulticommunityGuard],
      },
      {
        path: "loket",
        loadChildren: () => import("./loket/loket.module").then((m) => m.LoketModule),
        canActivateChild: [IsMulticommunityGuard],
      },
      {
        path: "ambtenaren",
        loadChildren: () => import("./officials/officials.module").then((m) => m.OfficialsModule),
        canActivateChild: [IsMulticommunityGuard],
      },
      {
        path: "eigenaars",
        loadChildren: () => import("./owners/owners.module").then((m) => m.OwnersModule),
        canActivateChild: [IsMulticommunityGuard],
      },
      {
        path: "werkopdrachten",
        loadChildren: () => import("./work-assignments/work-assignments.module").then((m) => m.WorkAssignmentsModule),
        canActivateChild: [IsMulticommunityGuard],
      },
      {
        path: "rapportering",
        loadChildren: () => import("./reporting/reporting.module").then((m) => m.ReportingModule),
      },
      {
        path: "vermoedenslijst",
        loadChildren: () => import("./suspects/suspects.module").then((m) => m.SuspectsModule),
      },
      {
        path: "export-financiele-dienst",
        loadChildren: () =>
          import("./export-financial-service/export-financial-service.module").then((m) => m.ExportFinancialServiceModule),
        canActivateChild: [IsMulticommunityGuard],
      },
      { path: "export-overzicht", component: ExportOverviewComponent},
      { path: "download-center", component: DownloadCenterComponent},
      { path: "instellingen/vlok-import", component: VlokImportComponent },
      { path: "instellingen/bedrijfsgegevens", component: CompanyDetailsComponent, canDeactivate: [CanDeactivateGuard] },
      {
        path: "instellingen/brievenbeheer",
        loadChildren: () =>
          import("./settings/letters-management/letters-management.module").then((m) => m.LettersManagementModule),
        canActivateChild: [IsMulticommunityGuard],
      },
      {
        path: "vlok",
        loadChildren: () => import("./vlok/vlok.module").then((m) => m.VlokModule),
        canActivateChild: [IsMulticommunityGuard],
      },
      // {
      //   path: "bulk-generation",
      //   loadChildren: () => import("./bulk-generation/bulk-generation.module").then((m) => m.BulkGenerationModule)
      // },
      { path: "**", redirectTo: "dossiers", pathMatch: "full" },
    ],
  },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
      enableTracing: false,
      preloadingStrategy: PreloadAllModules
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
