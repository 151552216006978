<div fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="mat-headline">{{ title }}</div>
  </div>
  <div fxLayout="row">
    <div class="mat-subheading">{{ text }}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <button fxFlex="50%" type="button" mat-button [mat-dialog-close]="0">{{ noLabel || "NEE" }}</button>
    <button *ngIf="optionalLabel" fxFlex="50%" type="button" mat-button [mat-dialog-close]="1">{{ optionalLabel }}</button>
    <button fxFlex="50%" type="button" mat-raised-button color="primary" [mat-dialog-close]="2">{{ yesLabel || "JA" }}</button>
  </div>
</div>
