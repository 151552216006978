import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "src/app/shared/state/app.state";
import { Setting } from "../../shared/models/setting.model";
import { SettingsService } from "../../shared/services/settings.service";
import { Subscription } from "rxjs";
import { UIState } from "../../shared/state/ui.state";
import { AuthService } from "../../auth/auth.service";
import { OnDeactivate, OnDeactivatePayload } from "../../shared/guards/can-deactivate.guard";
import { environment } from "src/environments/environment";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { StorageService } from "../../shared/services/storage.service";
import { MessageService } from "../../shared/services/message.service";

@Component({
  selector: "app-company-details",
  templateUrl: "./company-details.component.html",
  styleUrls: ["./company-details.component.scss"],
})
export class CompanyDetailsComponent implements OnInit, OnDeactivate, OnDestroy {
  private subscriptions: Subscription[] = [];
  public environment: any;
  public isDebug: boolean = false;

  saving: boolean;
  allSettings: Setting[];

  settingsColumns: string[] = ["Description", "Value", "Type", "Comment", "Actions"];
  settingsDataSource: MatTableDataSource<Setting>;
  @ViewChild("settingsSort") settingsSort: MatSort;
  @ViewChild("settingsPaginator") settingsPaginator: MatPaginator;

  form: FormGroup = this.fb.group({
    NisCode: [null],
    Name: [null],
    ServiceCell: [null],
    Street: [null],
    HouseNumber: [null],
    PostalCode: [null],
    City: [null],
    Telephone: [null],
    Fax: [null],
    HasInventory: [false],
  });

  lastUpdated: Date;

  constructor(
    public appState: AppState,
    public authService: AuthService,
    public uiState: UIState,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private localStorageService: StorageService,
    private messageService: MessageService
  ) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.appState.allowedToChangeCommunity = false;
    this.lastUpdated = new Date(environment.lastUpdated);

    this.authService.hasSuperadmin().subscribe(hasSuperadmin => {
      if (!hasSuperadmin) {
        this.form.disable();
      }
  
      this.uiState.breadcrumbs$.next([
        {
          name: "Bedrijfsgegevens",
        },
      ]);
  
      this.getSettings();
    });
  }

  onDeactivate(): OnDeactivatePayload {
    return {
      hasChanges: this.form.dirty,
    };
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  save(): void {
    if (this.saving) {
      return;
    }

    if (this.form.invalid) {
      this.messageService.showSnackBarError("Gelieve alle verplichte velden in te vullen.");
      return;
    }

    this.saving = true;

    this.allSettings.find((x) => {
      if (x.Key === "Adm_NISCode") {
        x.Value = this.form.controls.NisCode.value;
      }
    });
    this.allSettings.find((x) => {
      if (x.Key === "Adm_Dienst_Cel") {
        x.Value = this.form.controls.ServiceCell.value;
      }
    });
    this.allSettings.find((x) => {
      if (x.Key === "Adm_Benaming") {
        x.Value = this.form.controls.Name.value;
      }
    });
    this.allSettings.find((x) => {
      if (x.Key === "Adm_Straat") {
        x.Value = this.form.controls.Street.value;
      }
    });
    this.allSettings.find((x) => {
      if (x.Key === "Adm_Nr") {
        x.Value = this.form.controls.HouseNumber.value;
      }
    });
    this.allSettings.find((x) => {
      if (x.Key === "Adm_PostCode") {
        x.Value = this.form.controls.PostalCode.value;
      }
    });
    this.allSettings.find((x) => {
      if (x.Key === "Adm_Gemeente") {
        x.Value = this.form.controls.City.value;
      }
    });
    this.allSettings.find((x) => {
      if (x.Key === "Adm_Telefoon") {
        x.Value = this.form.controls.Telephone.value?.length > 0 ? this.form.controls.Telephone.value : "/";
      }
    });
    this.allSettings.find((x) => {
      if (x.Key === "Adm_Telefax") {
        x.Value = this.form.controls.Fax.value?.length > 0 ? this.form.controls.Fax.value : "/";
      }
    });
    this.allSettings.find((x) => {
      if (x.Key === "Adm_HeeftInventaris") {
        x.Value = this.form.controls.HasInventory.value ? "1" : "0";
      }
    });

    this.subscriptions.push(
      this.settingsService.updateClientSettings(this.allSettings).subscribe(
        (settings: Setting[]) => {
          this.saving = false;

          this.getSettings();
        },
        (error: any) => {
          this.saving = false;
        },
      ),
    );
  }

  getSettings(): void {
    this.subscriptions.push(
      this.appState.settings$.subscribe((settings: Setting[]) => {
        this.allSettings = settings;
        this.settingsDataSource = new MatTableDataSource<Setting>(this.allSettings);
        this.settingsDataSource.sort = this.settingsSort;
        this.settingsDataSource.paginator = this.settingsPaginator;

        if (this.appState.activeCommunity$.value?.IsCommunity) {
          this.form.controls.NisCode.patchValue(this.allSettings.find((x) => x.Key === "Adm_NISCode")?.Value);
          this.form.controls.ServiceCell.patchValue(this.allSettings.find((x) => x.Key === "Adm_Dienst_Cel")?.Value);
          this.form.controls.Name.patchValue(this.allSettings.find((x) => x.Key === "Adm_Benaming")?.Value);
          this.form.controls.Street.patchValue(this.allSettings.find((x) => x.Key === "Adm_Straat")?.Value);
          this.form.controls.HouseNumber.patchValue(this.allSettings.find((x) => x.Key === "Adm_Nr")?.Value);
          this.form.controls.PostalCode.patchValue(this.allSettings.find((x) => x.Key === "Adm_PostCode")?.Value);
          this.form.controls.City.patchValue(this.allSettings.find((x) => x.Key === "Adm_Gemeente")?.Value);
          this.form.controls.Telephone.patchValue(this.allSettings.find((x) => x.Key === "Adm_Telefoon")?.Value);
          this.form.controls.Fax.patchValue(this.allSettings.find((x) => x.Key === "Adm_Telefax")?.Value);
          this.form.controls.HasInventory.patchValue(this.allSettings.find((x) => x.Key === "Adm_HeeftInventaris")?.Value === "1" ? true : false);
        }
      }),
    );
  }

  public refreshToken() {
    console.log(" refreshToken");

    this.authService.refresh().subscribe(
      (res) => {
        console.log("!!!! refreshToken");
        console.log(res);
      },
      (err) => {
        console.error("!!!! refreshToken");

        console.error();
      },
    );
  }

  public storageAccessTokenStoredAtEpoc: number;
  public storageExpiresAtEpoc: number;
  public storageIdTokenExpiresAtEpoc: number;
  public storageidTokenStoredAtEpoc: number;

  public storageAccessTokenStoredAt: Date;
  public storageExpiresAt: Date;
  public storageIdTokenExpiresAt: Date;
  public storageidTokenStoredAt: Date;

  public loadStorageAuthKeys() {
    this.storageAccessTokenStoredAtEpoc = this.localStorageService.get("access_token_stored_at");
    this.storageExpiresAtEpoc = this.localStorageService.get("expires_at");
    this.storageIdTokenExpiresAtEpoc = this.localStorageService.get("id_token_expires_at");
    this.storageidTokenStoredAtEpoc = this.localStorageService.get("id_token_stored_at");

    if (this.storageAccessTokenStoredAtEpoc) {
      this.storageAccessTokenStoredAt = new Date(0); // this way we can set epoch time
      this.storageAccessTokenStoredAt.setUTCMilliseconds(this.storageAccessTokenStoredAtEpoc);
    }
    if (this.storageExpiresAtEpoc) {
      this.storageExpiresAt = new Date(0); // this way we can set epoch time
      this.storageExpiresAt.setUTCMilliseconds(this.storageExpiresAtEpoc);
    }
    if (this.storageIdTokenExpiresAtEpoc) {
      this.storageIdTokenExpiresAt = new Date(0); // this way we can set epoch time
      this.storageIdTokenExpiresAt.setUTCMilliseconds(this.storageIdTokenExpiresAtEpoc);
    }
    if (this.storageidTokenStoredAtEpoc) {
      this.storageidTokenStoredAt = new Date(0); // this way we can set epoch time
      this.storageidTokenStoredAt.setUTCMilliseconds(this.storageidTokenStoredAtEpoc);
    }
  }
}
