import * as _ from "lodash";
import { BehaviorSubject, from, Subscription } from "rxjs";
import { Dossier } from "../models/dossier.model";
import { Component, Injectable, OnDestroy } from "@angular/core";
import { Official } from "../models/official.model";
import { Owner } from "../models/owner.model";
import { Setting } from "../models/setting.model";
import { StorageService } from "../services/storage.service";
import { Community } from "../models/community.model";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../components/notification-dialog/notification-dialog.component";
import { Logger } from "../helpers/logger";
import { SettingsGebType } from "../models/settings-geb-type.model";
import { LoketDossier } from "../models/loket-dossier.model";

@Injectable({
  providedIn: "root",
})
export class AppState implements OnDestroy {
  private subscriptions: Subscription[] = [];

  activeCommunity$: BehaviorSubject<Community> = new BehaviorSubject<Community>(null);

  previousNisCode: string;
  //vannillaApiUrl: string;
  baseApiUrl: string;
  dataApiUrl: string;
  dataLoketApiUrl: string;

  authTenantId: string;
  authClientId: string;

  // initialize with empty array, otherwise app will go boom!!!
  // these are the communities available for the user, filterd on claims (or all in case superadmin)
  availableCommunities$: BehaviorSubject<Community[]> = new BehaviorSubject<Community[]>([]);
  communities$: BehaviorSubject<Community[]> = new BehaviorSubject<Community[]>([]);

  settings$: BehaviorSubject<Setting[]> = new BehaviorSubject<Setting[]>(null);
  settings: Setting[];
  settingsGebType$: BehaviorSubject<SettingsGebType[]> = new BehaviorSubject<SettingsGebType[]>(null);
  settingsGebType: SettingsGebType[];
  officials$: BehaviorSubject<Official[]> = new BehaviorSubject<Official[]>([]);
  searchOwners$: BehaviorSubject<Owner[]> = new BehaviorSubject<Owner[]>([]);
  lastVisitDossiers$: BehaviorSubject<Dossier[]> = new BehaviorSubject<Dossier[]>([]);
  lastTouchedDossiers$: BehaviorSubject<Dossier[]> = new BehaviorSubject<Dossier[]>([]);
  lastVisitLoketDossiers$: BehaviorSubject<LoketDossier[]> = new BehaviorSubject<LoketDossier[]>([]);
  lastTouchedLoketDossiers$: BehaviorSubject<LoketDossier[]> = new BehaviorSubject<LoketDossier[]>([]);
  moduleVerwaarlozing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  moduleLeegstand$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  moduleVLOK$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  moduleWoningkwaliteit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  moduleSecondresidence$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // apm$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  allowedToChangeCommunity: boolean = true;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private matDialog: MatDialog,
    private logger: Logger,
  ) {
    const settingsCache: Setting[] = this.storageService.get("nx.app.settings");
    if (settingsCache) {
      this.settings$.next(settingsCache);
    }

    this.subscriptions.push(
      this.settings$.subscribe((settings: Setting[]) => {
        this.settings = settings;
        this.storageService.set("nx.app.settings", settings);
      }),
    );

    this.subscriptions.push(
      this.settingsGebType$.subscribe((settingsGeb: SettingsGebType[]) => {
        this.settingsGebType = settingsGeb;
        this.storageService.set("nx.app.settingsGeb", settingsGeb);
      }),
    );

    const domain = this.getDomain();
    if (window.location.hostname == "localhost") {
      const nxCodeDev: Community = {
        Name: "dev-default-dev", // for development only
        NxCode: "dev-dev-dev", // for development only
        IsCommunity: false, // for development only
        RedirectUrl: null
      };

      this.activeCommunity$.next(this.storageService.get("nx.app.community") || nxCodeDev);
    } else {
      const nxCodeDefault: Community = {
        Name: "default", // default value in name used to indicate that this is a default, can be moved to a bool flag
        NxCode: window.location.hostname.replace(`.${domain}`, ""),
        IsCommunity: false, // defaults to dummy value, will be replaced after login
        RedirectUrl: null
      };

      this.activeCommunity$.next(this.storageService.get("nx.app.community") || nxCodeDefault);
    }

    if (domain.includes("01") || domain == "") {
      this.authTenantId = "316a7162-c446-4f99-beb2-fe2c2f7187c4";
      this.authClientId = "25dd7897-0a05-4fe3-8703-6e80a9756828";
    } else if (domain.includes("02")) {
      this.authTenantId = "ac5f1561-7773-4b38-8334-30bad62b0182";
      this.authClientId = "f482e51c-3d7e-47fa-ae62-4ff914e6c3cf";
    } else {
      this.authTenantId = "3fa5a837-e3ee-4ced-9456-a9b3def693c5";
      this.authClientId = "77b6396b-0426-45e2-a27d-91d5c48a8641";
    }

    this.subscriptions.push(
      this.activeCommunity$.subscribe((nxCodeTmp: Community) => {
        this.logger.info("apstate niscode");

        this.storageService.set("nx.app.community", nxCodeTmp);

        this.baseApiUrl = environment.api;
        this.baseApiUrl = this.baseApiUrl.replace("${api-url-variable}", `${nxCodeTmp.NxCode}.${domain}`);
        this.dataApiUrl = environment.data.replace("${api-url-variable}", `${nxCodeTmp.NxCode}.${domain}`);
        this.dataLoketApiUrl = environment.dataLoket.replace("${api-url-variable}", `${nxCodeTmp.NxCode}.${domain}`);

        //this.vannillaApiUrl = this.baseApiUrl.replace("${api-url-variable}", `${nxCodeTmp.NxCode}.neglect-x.be`);
        this.logger.info("AppState - constructor - baseApiUrl", this.baseApiUrl);

        if (this.previousNisCode && nxCodeTmp.NxCode && this.previousNisCode !== nxCodeTmp.NxCode) {
          if (!nxCodeTmp.RedirectUrl) {
            const dialogRef = this.matDialog.open(NotificationDialogComponent, {
              width: "550px",
              data: {
                title: `${nxCodeTmp.Name} (${nxCodeTmp.NxCode})`,
                text: `Opgelet! Je schakelt over naar ${nxCodeTmp.Name} (${nxCodeTmp.NxCode})`,
              },
            });

            dialogRef.afterClosed().subscribe(() => {
              from(router.navigate(["/"])).subscribe(() => window.location.reload());
            });
          } else {
            from(router.navigate([nxCodeTmp.RedirectUrl])).subscribe(() => window.location.reload());
          }
        }
        this.previousNisCode = nxCodeTmp.NxCode;
      }),
    );

    if (this.activeCommunity$.value?.IsCommunity) {
      let dossiersVisit = this.storageService.get(`nx.dossiers.last-visited.${this.activeCommunity$.value.NxCode}`);
      if (dossiersVisit) this.lastVisitDossiers$.next(dossiersVisit);
      let dossiersTouched = this.storageService.get(`nx.dossiers.last-touched.${this.activeCommunity$.value.NxCode}`);
      if (dossiersTouched) this.lastTouchedDossiers$.next(dossiersTouched);

      let loketDossiersVisit = this.storageService.get(`nx.loket-dossiers.last-visited.${this.activeCommunity$.value.NxCode}`);
      if (loketDossiersVisit) this.lastVisitLoketDossiers$.next(loketDossiersVisit);
      let loketDossiersTouched = this.storageService.get(`nx.loket-dossiers.last-touched.${this.activeCommunity$.value.NxCode}`);
      if (loketDossiersTouched) this.lastTouchedLoketDossiers$.next(loketDossiersTouched);
    }
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  addDossierToLastVisitDossiers(dossier: Dossier): void {
    if (!dossier) return;

    let dossiers = this.lastVisitDossiers$.value as Dossier[];
    dossiers.unshift(dossier);
    dossiers = _.uniqBy(dossiers, "Code");

    if (dossiers.length > 5) {
      while (dossiers.length > 5) {
        dossiers.pop();
      }
    }

    this.lastVisitDossiers$.next(dossiers);
    this.storageService.set(`nx.dossiers.last-visited.${this.activeCommunity$.value.NxCode}`, dossiers);
  }

  addlastTouchedDossiers(dossier: Dossier): void {
    if (!dossier) return;

    let dossiers = this.lastTouchedDossiers$.value as Dossier[];
    dossiers.unshift(dossier);
    dossiers = _.uniqBy(dossiers, "Code");

    if (dossiers.length > 5) {
      while (dossiers.length > 5) {
        dossiers.pop();
      }
    }

    this.lastTouchedDossiers$.next(dossiers);
    this.storageService.set(`nx.dossiers.last-touched.${this.activeCommunity$.value.NxCode}`, dossiers);
  }

  addDossierToLastVisitLoketDossiers(dossier: LoketDossier): void {
    if (!dossier) return;

    let dossiers = this.lastVisitLoketDossiers$.value as LoketDossier[];
    dossiers.unshift(dossier);
    dossiers = _.uniqBy(dossiers, "Code");

    if (dossiers.length > 5) {
      while (dossiers.length > 5) {
        dossiers.pop();
      }
    }

    this.lastVisitLoketDossiers$.next(dossiers);
    this.storageService.set(`nx.loket-dossiers.last-visited.${this.activeCommunity$.value.NxCode}`, dossiers);
  }

  addlastTouchedLoketDossiers(dossier: LoketDossier): void {
    if (!dossier) return;

    let dossiers = this.lastTouchedLoketDossiers$.value as LoketDossier[];
    dossiers.unshift(dossier);
    dossiers = _.uniqBy(dossiers, "Code");

    if (dossiers.length > 5) {
      while (dossiers.length > 5) {
        dossiers.pop();
      }
    }

    this.lastTouchedLoketDossiers$.next(dossiers);
    this.storageService.set(`nx.loket-dossiers.last-touched.${this.activeCommunity$.value.NxCode}`, dossiers);
  }

  removeDossierFromLastVisitDossiers(dossierCode: string): void {
    if (!dossierCode) {
      return;
    }

    let dossiers = this.lastVisitDossiers$.value as Dossier[];
    dossiers = dossiers.filter((dossier: Dossier) => dossier.Code !== dossierCode);
    dossiers = _.uniqBy(dossiers, "Code");

    this.lastVisitDossiers$.next(dossiers);
    this.storageService.set(`nx.dossiers.last-visited.${this.activeCommunity$.value.NxCode}`, dossiers);
  }

  removelastTouchedDossiers(dossierCode: string): void {
    if (!dossierCode) {
      return;
    }

    let dossiers = this.lastTouchedDossiers$.value as Dossier[];
    dossiers = dossiers.filter((dossier: Dossier) => dossier.Code !== dossierCode);
    dossiers = _.uniqBy(dossiers, "Code");

    this.lastTouchedDossiers$.next(dossiers);
    this.storageService.set(`nx.dossiers.last-touched.${this.activeCommunity$.value.NxCode}`, dossiers);
  }

  getDomain(): string {
    let domain = "localhost";
    const domainSplit = window.location.hostname.split(".");
    if (domainSplit.length > 0) {
      domain = domainSplit.slice(1).join(".");
    }

    return domain;
  }
}
