import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginCredentials } from "src/app/shared/models/auth.model";
import { AuthService } from "src/app/auth/auth.service";
import { environment } from "src/environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { Logger } from "src/app/shared/helpers/logger";
import { Router } from "@angular/router";

@Component({
  selector: "app-auth-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class AuthLoginComponent implements OnInit {
  loginForm: FormGroup = this.fb.group({
    Email: [{ value: null, disabled: true }, [Validators.required]],
    Password: [{ value: null, disabled: true }, [Validators.required]],
  });
  errorMessage: string;

  ssoLink = environment.sts;
  public environment;

  constructor(
    private authService: AuthService, 
    private fb: FormBuilder, 
    private logger: Logger,
    private router: Router
  ) {
    this.environment = environment;
  }

  ngOnInit(): void {
    // no need to log out the user if they're already logged in? just redirect them to main page.
    this.authService.isAuthenticated().subscribe(isAuthenticated => {
      if (!isAuthenticated) {
        this.authService.logout(true);
        return;
      }

      this.router.navigateByUrl("/");
    });
  }

  // login with resource owner password (input boxes on nx login screen)
  login(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.errorMessage = null;

    const loginCredentials: LoginCredentials = { ...this.loginForm.getRawValue() };
    this.authService.login(loginCredentials).subscribe(
      () => {
        // successfull login with resource owner password
      },
      (response: HttpErrorResponse) => {
        if (response?.error?.error_description === "invalid_username_or_password") {
          this.errorMessage = "E-mailadres of wachtwoord is onjuist.";
        }
        this.logger.error(response);
      },
    );
  }
  loginWithSSO(): void {
    this.authService.loginWithSSO();
  }

  logoutWithSSO(): void {
    this.authService.logout(false);
  }
}
