// tslint:disable: no-console
// tslint:disable: typedef

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AppInsightsService } from "../services/app-insights.service";

@Injectable({
  providedIn: "root",
})
export class Logger {
  constructor(private appInsights: AppInsightsService) {
    //  console.log(" !!!!initialize logger");
  }

  debug = localStorage.getItem("debug") || !environment.production || environment.test;

  log(...args) {
    if (this.debug) {
      console.log.apply(console, args);
    }
  }

  info(...args) {
    if (this.debug) {
      console.info.apply(console, args);
    }
  }

  // why are there here ...args and console apply ????
  errorArgs(...args) {
    if (this.debug) {
      console.error.apply(console, args);
    }
  }

  error(obj: any) {
    console.error(obj);
    try {
      this.appInsights.logException(obj, 1);
    } catch (error) {
      console.log(error);
    }
  }

  count(arg) {
    if (this.debug) {
      console.count(arg);
    }
  }

  group(arg) {
    if (this.debug) {
      console.group(arg);
    }
  }

  groupEnd() {
    if (this.debug) {
      console.groupEnd();
    }
  }

  time(arg) {
    if (this.debug) {
      console.time(arg);
    }
  }

  timeEnd(arg) {
    if (this.debug) {
      console.timeEnd(arg);
    }
  }
  ais_logPageView(name?: string, url?: string) {
    if (environment.appInsightsInstrumentationKey.trim() != "") {
      this.appInsights.logPageView(name, url);
    }
  }

  ais_event(name: string, properties?: { [key: string]: any }) {
    if (environment.appInsightsInstrumentationKey.trim() != "") {
      this.appInsights.logEvent(name, properties);
    }
  }
}
