import { Injectable } from "@angular/core";
import { ApplicationInsights, Telemetry } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppInsightsService {
  public applicationInsights: ApplicationInsights;
  constructor() {
    //console.log("!!!! init applicationInsights");
    //console.log(this.applicationInsights);
    if (environment.appInsightsInstrumentationKey.trim() == "") {
      console.log("*** No appInsightsInstrumentationKey available");
    } else {
      if (!this.applicationInsights) {
        this.applicationInsights = new ApplicationInsights({
          config: {
            instrumentationKey: environment.appInsightsInstrumentationKey,
            enableCorsCorrelation: true,
            enableAutoRouteTracking: true,

            /* other configuration options */
          }, // title of current webpage is passed to app insigth use pagetitle
        });
      }
      this.applicationInsights.loadAppInsights();
      // this.applicationInsights.trackPageView();

      // TODO: under dev, not yet fully working
      var telemetryInitializer = (envelope: any) => {
        //  envelope.Component.Version = "v99.99.99";
        envelope.tags["ai.cloud.role"] = environment.InsightsAppName;
      };
      this.applicationInsights.addTelemetryInitializer(telemetryInitializer);
    }
  }

  logPageView(name?: string, url?: string) {
    if (!this.isInitialized()) {
      return;
    }

    // option to call manually
    this.applicationInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (!this.isInitialized()) {
      return;
    }

    this.applicationInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (!this.isInitialized()) {
      return;
    }

    this.applicationInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    if (!this.isInitialized()) {
      return;
    }

    this.applicationInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (!this.isInitialized()) {
      return;
    }

    this.applicationInsights.trackTrace({ message: message }, properties);
  }

  isInitialized(): boolean {
    return this.applicationInsights != null;
  }
}
