import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NiscodeOfficialRoles, UserCommunity } from 'src/app/shared/models/user-community.model';
import { Role, User } from 'src/app/shared/models/user.model';
import { AdminService } from 'src/app/shared/services/admin.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AppState } from 'src/app/shared/state/app.state';
import { UIState } from 'src/app/shared/state/ui.state';

@Component({
  selector: 'app-beheersrechten',
  templateUrl: './beheersrechten.component.html',
  styleUrls: ['./beheersrechten.component.scss']
})
export class BeheersrechtenComponent implements OnInit, OnDestroy {

  filterText: string = '';
  appBeheersrechtenColumns: string[] = [ "Gebruiker" ];
  clickedRow: User = null;
  setAll = false;
  roles: Role[];
  officials: UserCommunity;
  appBeheersrechtenDataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
  private subscriptions: Subscription[] = [];

  constructor(private adminService: AdminService,
    private appState: AppState,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    private uiState: UIState) { }

  ngOnInit(): void {
    this.authService.hasSuperadmin().subscribe((res: boolean) => {
      if (!res) {
        this.router.navigateByUrl("/");
        return;
      }

      this.getUsers();
      this.getRoles();

      this.uiState.breadcrumbs$.next([{
        name: "Gebruikersbeheer"
      }]);
    });
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public applyFilter(): void{
    this.appBeheersrechtenDataSource.filter = this.filterText.trim().toLowerCase();
    if (this.appBeheersrechtenDataSource.paginator) {
      this.appBeheersrechtenDataSource.paginator.firstPage();
    }
  }

  public removeFilter(): void {
    this.filterText = '';
    this.applyFilter();
  }

  public selectUnselectRow(row): void{
    if (this.clickedRow === row) {
      this.clickedRow = null;
    } else {
      this.clickedRow = row;
      this.getUserOptions();
    }
  }
  
  public updateUserRoles(): void {
    const parameter = {
      Id: this.officials.UserId,
      NationalRegister: this.clickedRow.NationalRegister,
      ListNiscodeOfficialRoles: this.officials.ListNiscodeOfficialRoles.map(e => ({
        OfficialCode: e.OfficialCode,
        Niscode: e.Niscode,
        Roles: (e.Roles as Role[]).filter(r => r.Selected).map(r => r.Id),
      } as NiscodeOfficialRoles))
    } as UserCommunity;
    
    this.subscriptions.push(this.adminService.updateUserRoles(parameter).subscribe(res =>{
      if (!res) {
        return;
      }
      
      this.messageService.showSnackBarInfo(`Success`);
      this.getUserOptions();
    }));
  }
  
  private getUsers(): void {
    this.subscriptions.push(this.adminService.getUsers().subscribe((res: User[]) => this.appBeheersrechtenDataSource = new MatTableDataSource<User>(res)));
  }
    
  private getRoles(): void {
    this.subscriptions.push(this.adminService.getRoles().subscribe((res: Role[]) => this.roles = res));
  }

  private getUserOptions(): void {
    if(!this.clickedRow){
      return;
    }
    this.subscriptions.push(this.adminService.getUserOptions(this.clickedRow).subscribe((res: UserCommunity) => {
      this.officials = {UserId: res.Id, Email: res.Email, ListNiscodeOfficialRoles: []};
      this.appState.communities$.value.forEach(c =>{
        const hasOfficial = res.ListNiscodeOfficialRoles.filter(nor => nor.Niscode === c.NxCode);
          this.officials.ListNiscodeOfficialRoles.push({
            IsCommunity: c.IsCommunity,
            OfficialCode: hasOfficial.length <= 0 ? '-1': hasOfficial[0].OfficialCode,
            Niscode: c.NxCode,
            NisName: c.Name,
            Roles: this.roles.map(r => {
              r.Selected = false;
              if (hasOfficial.length && (hasOfficial[0].Roles as number[]).indexOf(r.Id) > -1) {
                r.Selected = true;
              }
              return {...r};
            })
          } as NiscodeOfficialRoles);
        });
      })
    );
  }
  
}
