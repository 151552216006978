import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Response } from '../models/response.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppState } from '../state/app.state';
import { Role, User } from '../models/user.model';
import { Official } from '../models/official.model';
import { UserCommunity } from '../models/user-community.model';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http: HttpClient, private appState: AppState) { }

  getUsers(): Observable<User[]>{
    return this.http.get(`${this.appState.baseApiUrl}/admin/get-users`, httpOptions)
    .pipe(map((response: Response) => {
      if (!response.Success) { 
        return;
      }
      return response.Data;
    }));
  }

  getRoles(): Observable<Role[]>{
    return this.http.get(`${this.appState.baseApiUrl}/admin/get-roles`, httpOptions)
    .pipe(map((response: Response) => {
      if (!response.Success) { 
        return;
      }
      return response.Data;
    }));
  }

  getUserOptions(user: User): Observable<UserCommunity> {
    return this.http.get(`${this.appState.baseApiUrl}/admin/get-user-options/${user.Id}`, httpOptions)
    .pipe(map((response: Response) => {
      if (!response.Success) { 
        return;
      }
      return response.Data;
    }));
  }

  updateUserRoles(officials: UserCommunity): Observable<any> {
    return this.http
      .post<Response>(`${this.appState.baseApiUrl}/admin/user-role/update`, officials, httpOptions)
      .pipe(
        map((response: Response) => {
          if (!response.Success) return;
          return response.Data;
        }),
      );
  }
  
  getList(community: string): Observable<Official[]> {
    const domain = this.getDomain();
    let url = environment.api.replace("${api-url-variable}", `${community}.${domain}`);

    return this.http.get<Official[]>(`${url}/officials`, httpOptions);
  }

  getDomain(): string {
    let domain = "localhost";
    const domainSplit = window.location.hostname.split(".");
    if (domainSplit.length > 0) {
      domain = domainSplit.slice(1).join(".");
    }

    return domain;
  }
}
