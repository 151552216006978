import { AfterContentChecked, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { from, Subscription } from "rxjs";
import { Community } from "../shared/models/community.model";
import { AppState } from "../shared/state/app.state";

@Component({
  selector: "app-open",
  templateUrl: "./open.component.html",
  styleUrls: ["./open.component.scss"],
})
export class OpenDossierComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private appState: AppState,
    private router: Router,
    private route: ActivatedRoute
  ) {
    
  }

  ngOnInit(): void {
    this.subscriptions.push(this.route.paramMap.subscribe((params: ParamMap) => {
      const code = params.get("code");
      const split = code?.split('-') ?? null;

      if (!code || split?.length != 2) {
        this.router.navigateByUrl("/");
        return;
      }

      let niscode = split[0];
      let dossier = split[1];
      
      // ! VOL + 5 numbers
      if (dossier.length != 8) {
        this.router.navigateByUrl("/");
        return;
      }

      if (this.appState.activeCommunity$.value.NxCode === niscode) {
        from(this.router.navigate([`/dossiers/${dossier}`])).subscribe(x => window.location.reload());
        return;
      }

      this.subscriptions.push(this.appState.availableCommunities$.subscribe((communities: Community[]) => {
        if (communities.length == 0) {
          return;
        }

        let community = communities.find(x => x.NxCode === niscode);
        if (!community) {
          this.router.navigateByUrl("/");
          return;
        }

        if (this.appState.activeCommunity$.value.NxCode !== community.NxCode) {
          // ! put empty settings to prevent cached settings from applying to other communities
          this.appState.settings$.next([]);
        }
  
        community.RedirectUrl = `/dossiers/${dossier}`;

        this.appState.activeCommunity$.next(community);
      }));
    }));
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}