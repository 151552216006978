import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Initialization } from "@microsoft/applicationinsights-web/types/Initialization";
import { environment } from "src/environments/environment";
import { Logger } from "../helpers/logger";

@Injectable({ providedIn: "root" })
export class ServiceWorkerUpdateService {
  constructor(private swUpdate: SwUpdate, private logger: Logger) {
    this.logger.info("*** ServiceWorkerUpdateService - constructor");
  }

  public initialize() {
    // always log the version
    console.log(`Neglect-X v${environment.appVersion} | Updates ${this.swUpdate.isEnabled ? "en" : "dis"}abled`);

    if (!this.swUpdate.isEnabled) {
      return;
    }

    // do we need this??
    //if (this.swUpdate.isEnabled) {
    //this.subs.push(
    // normally we don't need to unsub from a service worker
    this.swUpdate.available.subscribe((event) => {
      this.logger.info("*** current version is", event.current);
      this.logger.info("*** available version is", event.available);
      
      window.location.reload();
    });

    this.swUpdate.activated.subscribe((event) => {
      this.logger.info("old version was", event.previous);
      this.logger.info("new version is", event.current);
    });
    // } else {
    //   this.logger.info("*** INFO: swUpdate is not enabled");
    // }

    this.swUpdate.checkForUpdate();
  }
}
