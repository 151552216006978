<div fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="mat-headline">{{ title }}</div>
  </div>
  <div fxLayout="row">
    <div class="mat-subheading">{{ text }}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <button fxFlex="100%" type="button" mat-raised-button color="primary" mat-dialog-close>OK</button>
  </div>
</div>
