import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Official } from 'src/app/shared/models/official.model';
import { NiscodeOfficialRoles } from 'src/app/shared/models/user-community.model';
import { Role, User } from 'src/app/shared/models/user.model';
import { AdminService } from 'src/app/shared/services/admin.service';
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from 'src/app/shared/components/notification-dialog/notification-dialog.component';
import { NXUser } from 'src/app/shared/models/auth.model';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-official-role',
  templateUrl: './official-role.component.html',
  styleUrls: ['./official-role.component.scss']
})
export class OfficialRoleComponent implements OnInit {

  @Input() niscodeOfficialRoles: NiscodeOfficialRoles; 
  @Input() roles: Role[]; 
  @Input() clickedRow :User;

  ambtenaar: Official = null;
  ambtenaren: Official[] = [];
  user: NXUser;
  private subscriptions: Subscription[] = [];

  constructor(private adminService: AdminService,private matDialog: MatDialog, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.niscodeOfficialRoles.IsCommunity) {
      this.getAmbtenaar();
    } else {
      this.ambtenaar = new Official();
      this.ambtenaar.Code = this.niscodeOfficialRoles.Niscode,
      this.ambtenaar.Name = this.niscodeOfficialRoles.NisName,
      this.ambtenaar.PersonResponsible = false;
      this.niscodeOfficialRoles.Roles = [];

      if (this.niscodeOfficialRoles.OfficialCode !== "-1") {
        this.ambtenaar.PersonResponsible = true;  
        this.niscodeOfficialRoles.Roles = this.roles.map(e => ({Selected: true,Id: e.Id } as Role));
      }
    }
    this.user = this.authService.getUser();
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.Code === o2.Code && o1.Name === o2.Name;
  }

  updateOfficial(value: Official): void {
    if (value === null) {
      this.ambtenaar = value;
      this.niscodeOfficialRoles.Roles.map(e => e.Selected = false);
      this.niscodeOfficialRoles.OfficialCode = "-1";
      return;
    }
    
    this.niscodeOfficialRoles.OfficialCode = value.Code;
  }

  comunityChanged(isCommunity: boolean){
    if (isCommunity) {
      return;
    }

    this.ambtenaar.Code = this.ambtenaar.PersonResponsible ? "1" : "-1";
    this.niscodeOfficialRoles.OfficialCode = this.ambtenaar.Code;
    this.niscodeOfficialRoles.Roles = [];

    // if (this.ambtenaar.PersonResponsible) {
    //   this.niscodeOfficialRoles.Roles = this.roles.map(e => ({Selected: true, Id: e.Id } as Role));
    // }
  }

  private getAmbtenaar(): void {
    this.subscriptions.push(this.adminService.getList(this.niscodeOfficialRoles.Niscode).subscribe( res => {
        if (this.niscodeOfficialRoles.OfficialCode) {
          res.map(official => this.ambtenaren.push({
            Code: official.Code,
            Name: official.Name
          } as any));
          
          const tmpOfficial = this.ambtenaren.filter(a => a.Code === this.niscodeOfficialRoles.OfficialCode);
          this.ambtenaar = tmpOfficial.length > 0 ? {
            Code: tmpOfficial[0].Code,
            Name: tmpOfficial[0].Name,
            PersonResponsible: !this.niscodeOfficialRoles.IsCommunity ? true : false,
          } as any : null;
        }
      }
    ));
  }

  private notifyUserOwnRole(selectedRole:Role): void {
    if(selectedRole.Selected && (this.clickedRow.Id == Number(this.user.Id))){
      this.matDialog.open(NotificationDialogComponent, {
        width: "550px",
        data: {
          title: `Waarschuwing`,
          text: `U bent uw eigen rollen aan het aanpassen!`,
        },
      });
    }
  }
}
