import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import {
  AppealType,
  BuildingState,
  BuildingTypeDescription,
  BulkGenerationStatusDescription,
  CadastralDescription,
  CadastralLocationDepartment,
  CadastralPlot,
  ContactType,
  CrabCode,
  CuratorType,
  HousingSupervisor,
  InitiationResearch,
  InitiativeRemoval,
  LegalForm,
  LocationType,
  ObjectionArgumentation1,
  ObjectionArgumentation2,
  ObjectionArgumentation3,
  ObjectionDecision,
  ObjectionState,
  PostalCode,
  ReasonExemption,
  ReasonRemoval,
  ReasonWithoutConsequence,
  RightInRem,
  RVVRecipient,
  Salutation,
  SubType,
  SurfaceSectionDescription,
  Title,
  TvlsDoorbell,
  TvlsMailbox,
  TypeRecipientContact,
  UbvDuration,
  UbvRoomLicensed,
  UbvStatus
} from "../models/vanilla.models";
import { ExemptionProvider } from "../models/exemption-provider.model";
import { ExemptionDecisionProvider } from "../models/exemption-decision.model";
import { ExemptionNotificationType } from "../models/exemption-notification-type.model";

@Injectable({
  providedIn: "root",
})
export class VanillaState {
  appealTypes$: BehaviorSubject<AppealType[]> = new BehaviorSubject<AppealType[]>([]);
  buildingStates$: BehaviorSubject<BuildingState[]> = new BehaviorSubject<BuildingState[]>([]);
  buildingTypeDescriptions$: BehaviorSubject<BuildingTypeDescription[]> = new BehaviorSubject<BuildingTypeDescription[]>([]);
  cadastralDescriptions$: BehaviorSubject<CadastralDescription[]> = new BehaviorSubject<CadastralDescription[]>([]);
  cadastralPlots$: BehaviorSubject<CadastralPlot[]> = new BehaviorSubject<CadastralPlot[]>([]);
  cadastralLocationDepartments$: BehaviorSubject<CadastralLocationDepartment[]> = new BehaviorSubject<
    CadastralLocationDepartment[]
  >([]);
  contactTypes$: BehaviorSubject<ContactType[]> = new BehaviorSubject<ContactType[]>([]);
  crabCodes$: BehaviorSubject<CrabCode[]> = new BehaviorSubject<CrabCode[]>([]);
  curatorTypes$: BehaviorSubject<CuratorType[]> = new BehaviorSubject<CuratorType[]>([]);
  housingSupervisors$: BehaviorSubject<HousingSupervisor[]> = new BehaviorSubject<HousingSupervisor[]>([]);
  legalForms$: BehaviorSubject<LegalForm[]> = new BehaviorSubject<LegalForm[]>([]);
  initiativeRemovals$: BehaviorSubject<InitiativeRemoval[]> = new BehaviorSubject<InitiativeRemoval[]>([]);
  objectionArgumentation1s$: BehaviorSubject<ObjectionArgumentation1[]> = new BehaviorSubject<ObjectionArgumentation1[]>([]);
  objectionArgumentation2s$: BehaviorSubject<ObjectionArgumentation2[]> = new BehaviorSubject<ObjectionArgumentation2[]>([]);
  objectionArgumentation3s$: BehaviorSubject<ObjectionArgumentation3[]> = new BehaviorSubject<ObjectionArgumentation3[]>([]);
  objectionDecisions$: BehaviorSubject<ObjectionDecision[]> = new BehaviorSubject<ObjectionDecision[]>([]);
  objectionStates$: BehaviorSubject<ObjectionState[]> = new BehaviorSubject<ObjectionState[]>([]);
  postalCodes$: BehaviorSubject<PostalCode[]> = new BehaviorSubject<PostalCode[]>([]);
  postalCodesFromNisCode$: BehaviorSubject<PostalCode[]> = new BehaviorSubject<PostalCode[]>([]);
  reasonExemptions$: BehaviorSubject<ReasonExemption[]> = new BehaviorSubject<ReasonExemption[]>([]);
  exemptionProviders$: BehaviorSubject<ExemptionProvider[]> = new BehaviorSubject<ExemptionProvider[]>([]);
  exemptionDecisionProviders$: BehaviorSubject<ExemptionDecisionProvider[]> = new BehaviorSubject<ExemptionDecisionProvider[]>([]);
  exemptionNotificationTypes$: BehaviorSubject<ExemptionNotificationType[]> = new BehaviorSubject<ExemptionNotificationType[]>([]);
  reasonRemovals$: BehaviorSubject<ReasonRemoval[]> = new BehaviorSubject<ReasonRemoval[]>([]);
  reasonRemovalsOO$: BehaviorSubject<ReasonRemoval[]> = new BehaviorSubject<ReasonRemoval[]>([]);
  reasonWithoutConsequences$: BehaviorSubject<ReasonWithoutConsequence[]> = new BehaviorSubject<ReasonWithoutConsequence[]>([]);
  rightInRems$: BehaviorSubject<RightInRem[]> = new BehaviorSubject<RightInRem[]>([]);
  rvvRecipients$: BehaviorSubject<RVVRecipient[]> = new BehaviorSubject<RVVRecipient[]>([]);
  salutations$: BehaviorSubject<Salutation[]> = new BehaviorSubject<Salutation[]>([]);
  locationType$: BehaviorSubject<LocationType[]> = new BehaviorSubject<LocationType[]>([])
  titles$: BehaviorSubject<Title[]> = new BehaviorSubject<Title[]>([]);
  initiationResearch$: BehaviorSubject<InitiationResearch[]> = new BehaviorSubject<InitiationResearch[]>([]);
  typeRecipientContacts$: BehaviorSubject<TypeRecipientContact[]> = new BehaviorSubject<TypeRecipientContact[]>([]);
  bulkgenerationStatusdescriptions$: BehaviorSubject<BulkGenerationStatusDescription[]> = new BehaviorSubject<BulkGenerationStatusDescription[]>([]);
  unhabitableSubTypes$: BehaviorSubject<SubType[]> = new BehaviorSubject<SubType[]>([]);
  ubvDuration$: BehaviorSubject<UbvDuration[]> = new BehaviorSubject<UbvDuration[]>([]);
  ubvRoomLicensed$: BehaviorSubject<UbvRoomLicensed[]> = new BehaviorSubject<UbvRoomLicensed[]>([]);
  ubvStatus$: BehaviorSubject<UbvStatus[]> = new BehaviorSubject<UbvStatus[]>([]);
  surfaceSectionDescription$: BehaviorSubject<SurfaceSectionDescription[]> = new BehaviorSubject<SurfaceSectionDescription[]>([]);
  tvlsDoorbells$: BehaviorSubject<TvlsDoorbell[]> = new BehaviorSubject<TvlsDoorbell[]>([]);
  tvlsMailboxes$: BehaviorSubject<TvlsMailbox[]> = new BehaviorSubject<TvlsMailbox[]>([]);
}
