import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService, TokenResponse } from "angular-oauth2-oidc";
import { from, Observable, Subscription, of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DossierState } from "../dossiers/dossier.state";
import { Logger } from "../shared/helpers/logger";
import { LoginCredentials, NXUser } from "../shared/models/auth.model";
import { Community } from "../shared/models/community.model";
import { Dossier } from "../shared/models/dossier.model";
import { DataFilter, DataFilterType, OperationType, User } from "../shared/models/user.model";
import { BuildingTypeDescription } from "../shared/models/vanilla.models";
import { MessageService } from "../shared/services/message.service";
import { SettingsService } from "../shared/services/settings.service";
import { StorageService } from "../shared/services/storage.service";
import { UserService } from "../shared/services/user.service";
import { AppState } from "../shared/state/app.state";
import { VanillaState } from "../shared/state/vanilla.state";
import { authConfig } from "./auth.config";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy, OnInit {
  private subscriptions: Subscription[] = [];
  public user: NXUser;

  constructor(
    private appState: AppState,
    public oauthService: OAuthService,
    private router: Router,
    private storageService: StorageService,
    private settingService: SettingsService,
    private logger: Logger,
    private messageService: MessageService,
    private userService: UserService,
    private dossierState: DossierState,
    private vanillaState: VanillaState
  ) {
    this.logger.info("AuthService - constructor");

    let config = authConfig;
    config.issuer = environment.sts.replace("${auth-tenant-id}", this.appState.authTenantId);
    config.clientId = this.appState.authClientId;
    config.scope = config.scope.replace("${auth-client-id}", this.appState.authClientId);

    this.oauthService.configure(config);

    this.subscriptions.push(
      from(this.oauthService.loadDiscoveryDocument()).subscribe(() => {
        this.logger.info("AuthService => loadDiscoveryDocument- subscribe ");

        // Automatically load user profile
        this.subscriptions.push(
          this.oauthService.events.pipe(filter((e) => e.type === "token_received")).subscribe(
            () => {
              //  First Login logic, otherwise token token_received is not trigger
              this.logger.info("AuthService - oauthService.events - token_received");

              this.userService.get().subscribe((user: User) => {
                this.storageService.set("nx.user", user);

                this.initializeUser(user);
                this.getCommunitiesAndSetActive(user);
              });
            },
            (err) => this.logger.error("Error loadDiscoveryDocument"),
          ),
        );

        // Refresh token
        this.oauthService.setupAutomaticSilentRefresh();
      }),
    );
  }

  initialize() {}

  ngOnInit(): void {
    this.logger.info("AuthService - ngOnInit");
  }

  ngOnDestroy(): void {
    this.logger.info("AuthService - ngOnDestroy");
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  login(credentials: LoginCredentials): Observable<TokenResponse> {
    this.logger.info("AuthService - login");
    this.oauthService.oidc = false;
    return from(this.oauthService.fetchTokenUsingPasswordFlow(credentials.Email, credentials.Password));
  }

  loginWithSSO(): void {
    this.logger.info("AuthService - loginWithSSO");
    this.oauthService.oidc = true;
    this.oauthService.initCodeFlow();
  }

  loginWithSSOCallback() {
    this.logger.info("AuthService - loginWithSSOCallback");
    this.oauthService.oidc = true;
    from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).subscribe((res) => {
      this.logger.info("AuthService =>  loginWithSSOCallback => loadDiscoveryDocumentAndTryLogin", res);
      this.logger.info("!! After Login !!"); // no reload logic here when a browser reload is done, the code in constructor is executed
    });
  }

  getCommunitiesAndSetActive(user: User) {
    this.subscriptions.push(
      this.hasSuperadmin().subscribe(hasSuperadmin => {
        this.subscriptions.push(
          this.settingService.getCommunities().subscribe((communities: Community[]) => {
            this.logger.info("AuthService -  navigateAfterLogin - user.NisCodes ", this.user.NisCodes);
            this.logger.info("AuthService -  navigateAfterLogin - communities ", communities);
    
            let availableCommunities: Community[];
            if (hasSuperadmin) {
              this.logger.info("AuthService - navigateAfterLogin - superadmin ");
              availableCommunities = communities;
            } else {
              availableCommunities = communities.filter((x) => this.user.NisCodes.includes(x.NxCode));
            }
            this.logger.info("AuthService - navigateAfterLogin -  availableCommunities :", availableCommunities);
    
            if (availableCommunities.length == 0) {
              this.logger.error("AuthService - navigateAfterLogin - NO AVAILABLE  COMMUNITES ");
              from(this.router.navigate(["/login"])).subscribe(() =>
                this.messageService.showSnackBarWarning("Geen toegangen beschikbaar."),
              );
              return;
            }
    
            availableCommunities = availableCommunities.sort((a, b) => {
              // ! always put IGS communities first
              if (!a.IsCommunity || !b.IsCommunity) {
                return 1;
              }
              
              return a.Name.localeCompare(b.Name);
            });

            this.appState.availableCommunities$.next(availableCommunities);
    
            let activeCommunity: Community;
            activeCommunity = this.storageService.get("nx.app.community");
            // (activeCommunity.Name == "default" || availableCommunities.findIndex((c) => c.NxCode == activeCommunity.NxCode) == -1)
            if (activeCommunity && activeCommunity.Name == "default") {
              this.logger.info("AuthService - navigateAfterLogin - Take first ");
    
              // this is the section for the initial value
              activeCommunity = availableCommunities[0];
            } else {
              this.logger.info("AuthService - navigateAfterLogin - check else");
    
              // take the one from the local storage if its not the default one
              // if non are present in local storage take the first one ==> isn't there not always one present????
              // when the storage active comunity is one where the user has no rights
              if (availableCommunities.filter((x) => activeCommunity.NxCode.includes(x.NxCode)).length == 0) {
                this.logger.info("AuthService - context switch - remove -  nx.app.community :", activeCommunity);
                this.storageService.remove("nx.app.community");
              }
    
              activeCommunity = this.storageService.get("nx.app.community") || availableCommunities[0];
            }
            
            this.logger.info("AuthService - navigateAfterLogin - activeCommunity ", activeCommunity);
    
            // main route for IGS, (hopefully) fixes login issue
            // if (!activeCommunity.IsCommunity) {
            //   this.storageService.set("nx.app.login-redirect", "/rapportering");
            // }
    
            this.appState.activeCommunity$.next(activeCommunity);
    
            let officialCodes = user.OfficialCodes.filter(x => x.Niscode === activeCommunity.NxCode);
            if (officialCodes.length > 0) {
              this.user.ActiveOfficialCode = officialCodes[0].OfficialCode; 
            }
            
            this.logger.info(`User.OfficialCodes -> ${this.user.OfficialCodes}`);
            this.logger.info(`User.ActiveOfficialCode -> ${this.user.ActiveOfficialCode}`);
    
            this.redirectLogicAfterlogin();
          }),
        );
      })      
    );
  }
  // go back to the page where you left if token expires => good idea if it works, seems a buggy flow at the moment..
  // buggy for in case when you dont have permission, guard to login
  redirectLogicAfterlogin() {
    this.logger.info("AuthService - redirectLogicAfterlogin");

    // redirect logic
    if (this.router.url.startsWith("/login") || this.router.url.startsWith("/auth/sso-callback")) {
      this.logger.info("AuthService - navigateAfterLogin  : redirect logic ");

      // const redirect = this.storageService.get("nx.app.login-redirect");
      // this.logger.info(redirect);
      // if (redirect) {
      //   this.logger.info("AuthService - navigateAfterLogin - redirect");
      //   this.logger.info(redirect);

      //   // reload????
      //   //from(this.router.navigateByUrl(redirect)).subscribe(() => window.location.reload());
      //   from(this.router.navigateByUrl(redirect)).subscribe(() => window.location.reload());
      //   this.storageService.remove("nx.app.login-redirect");
      // } else {
      // this.logger.info("navigateAfterLogin - navigateByUrl");

      this.router.navigate(["/"]);
      // console.log("*** SvenDebug: RELOAD ");
      // from(this.router.navigate(["/"])).subscribe(() => window.location.reload());
      //}
    }
  }

  isAuthenticated(): Observable<boolean> {
    this.logger.info("AuthService => isAuthenticated", this.oauthService.hasValidAccessToken());

    if (!this.oauthService.hasValidAccessToken()) {
      // ! todo: attempt using refresh token instead of throwing user out?
      return of(false);
    }
    
    return this.ensureUserInitialized().pipe(map(r => {
      const nxUser = this.storageService.get("nx.user");

      const isAuthenticated: boolean = this.oauthService.hasValidAccessToken() && !!this.user;
      if (isAuthenticated && this.appState.availableCommunities$.value?.length == 0) {
        this.getCommunitiesAndSetActive(nxUser);
      }
      
      return isAuthenticated;
    }));
  }

  ensureUserInitialized(): Observable<boolean> {
    if (this.user) {
      return of(true);
    }

    return this.userService.get().pipe(map(user => {
      this.initializeUser(user);
  
      this.storageService.set("nx.user", user);

      return true;
    }));
  }

  public initializeUser(user: User): NXUser {
    if (!user) {
      return null;
    }

    if (this.user) {
      return this.user;
    }

    this.logger.info("AuthService => isAuthenticated - initializeUser");

    this.user = new NXUser();

    this.user.Id = user.Id.toString();
    this.user.Email = user.Email;
    this.user.Username = user.Name;
    this.user.Name = user.Name;

    this.user.DateNBF = new Date(0); // this way we can set epoch time
    this.user.DateNBF.setUTCSeconds(this.claims.nbf);
    this.user.DateIAT = new Date(0);
    this.user.DateIAT.setUTCSeconds(this.claims.iat);
    this.user.DateEXP = new Date(0);
    this.user.DateEXP.setUTCSeconds(this.claims.exp);
    this.user.DateAuthTime = new Date(0);
    this.user.DateAuthTime.setUTCSeconds(this.claims.auth_time);

    this.user.NisCodes = user.Niscodes;

    if (user.OfficialCodes) {
      this.user.OfficialCodes = user.OfficialCodes.map(v => v.OfficialCode);
    }

    this.user.Roles = user.Roles;
    this.user.Permissions = user.Permissions;

    // if (claims.nx_official_code) {
    //   if (typeof claims.nx_official_code === "string") {
    //     this.user.OfficialCodes = [claims.nx_official_code];
    //     // } else if (claims.nx_official_code.length > 0) {
    //     //   const officialCodeIndex: number = claims.nx_official_code.findIndex((x) => x.startsWith(this.settingService.getSettingByKey("Adm_NISCode").Value));
    //     //   if (officialCodeIndex > -1) {
    //     //     user.OfficialCode = claims.nx_official_code[officialCodeIndex];
    //     //   }
    //   } else {
    //     this.user.OfficialCodes = claims.nx_official_code;
    //   }
    // }

    // if (claims.role) {
    //   if (typeof claims.role === "string") {
    //     this.user.Roles = [claims.role];
    //   } else {
    //     this.user.Roles = claims.role;
    //   }
    // }

    return this.user;
  }

  hasModule(module: string): boolean {
    let moduleKey = 'module-' + module;
    let isModuleEnabled = this.settingService.getSettingByKey(moduleKey)?.Value; 
    return isModuleEnabled == 'true' ?? false;
 }

  get claims(): any {
    return this.oauthService.getIdentityClaims();
  }

  get access_token(): string {
    return this.oauthService.getAccessToken();
  }

  hasRole(role: string): boolean {
    return (this.user?.Roles?.filter(x => x.Name === role).length ?? 0) > 0;
  }

  // hasScreenResourceWithDossier(screen: ScreenType, operation: OperationType, dossier: Dossier): Observable<boolean> {
  //   let permissionObs = this.hasScreenResource(screen, operation);
  //   if (dossier) {
  //     return permissionObs.pipe(map(r => {
  //       if (!r) {
  //         console.log("?????");
  //         return false;
  //       }
// 
  //       if (operation == OperationType.READ) {
  //         return this.checkDataFilterCanRead(dossier);
  //       } else {
  //         return this.checkDataFilterCanWrite(dossier);
  //       }
  //     }));
  //   }
// 
  //   return permissionObs;
  // }

  getAllowedDataFilterDossierTypes(): Observable<string[]> {
    return this.ensureUserInitialized().pipe(map(r => {
      let list: string[] = [];
      
      for (const role of this.user?.Roles) {
        for (const dataFilter of role.DataFilters) {
          if (dataFilter.CanWrite) {
            switch (dataFilter.DataFilterType) {
              case DataFilterType.BUILDING_ECONOMIC:
                list.push("bedrijven");
                break;
              case DataFilterType.BUILDING_LIVING:
                list.push("wonen");
                break;
            }
          }
        }
      }

      return list;
    }));
  }

  hasScreenResourceWithDossier(screen: string, operation: OperationType, dossier: Dossier): Observable<boolean> {
    return this.ensureUserInitialized().pipe(map(r => {
      for (const role of this.user?.Roles) {
        let filters: DataFilter[] = [];
        let filter: DataFilter;

        let filterCanRead = false;
        let filterCanWrite = false;

        switch (dossier?.BuildingTypeDossier?.toLowerCase()) {
          case "wonen":
            filters = role.DataFilters.filter(x => x.DataFilterType == DataFilterType.BUILDING_LIVING);
            filter = filters.length > 0 ? filters[0] : null;
            break;
          case "bedrijven":
            filters = role.DataFilters.filter(x => x.DataFilterType == DataFilterType.BUILDING_ECONOMIC);
            filter = filters.length > 0 ? filters[0] : null;
            break;
        }

        if (filter?.CanRead ?? true) {
          filterCanRead = true;
        }

        if (filter?.CanWrite ?? true) {
          filterCanWrite = true;
        }

        for (const resource of role.Resources.filter(x => x.Type === "SCREEN" && x.Key == screen ||
                                                     x.Key == "NX_SUPERADMIN")) {
          if (role.Niscode !== this.appState.activeCommunity$.value.NxCode && resource.Key !== "NX_SUPERADMIN") {
            continue;
          }
          
          switch (operation) {
            case OperationType.CREATE:
              if (resource.IsCreatable && filterCanWrite) {
                return true;
              }
  
              break;
            case OperationType.READ:
              if (resource.IsReadable && filterCanRead) {
                return true;
              }
            
              break;
            case OperationType.UPDATE:
              if (resource.IsUpdatable && filterCanWrite) {
                return true;
              }
  
              break;
            case OperationType.DELETE:
              if (resource.IsDeletable && filterCanWrite) {
                return true;
              }
  
              break;
          }
        }
      }
  
      return false;
    }));
  }

  hasScreenResource(screen: string, operation: OperationType): Observable<boolean> {
    return this.ensureUserInitialized().pipe(map(r => {
      for (const role of this.user?.Roles) {
        for (const resource of role.Resources.filter(x => x.Type === "SCREEN" && x.Key == screen ||
                                                     x.Key == "NX_SUPERADMIN")) {
          if (role.Niscode !== this.appState.activeCommunity$.value.NxCode && resource.Key !== "NX_SUPERADMIN") {
            continue;
          }

          switch (operation) {
            case OperationType.CREATE:
              if (resource.IsCreatable) {
                return true;
              }
  
              break;
            case OperationType.READ:
              if (resource.IsReadable) {
                return true;
              }
            
              break;
            case OperationType.UPDATE:
              if (resource.IsUpdatable) {
                return true;
              }
  
              break;
            case OperationType.DELETE:
              if (resource.IsDeletable) {
                return true;
              }
  
              break;
          }
        }
      }
  
      return false;
    }));
  }

  hasPermission(permission: string): Observable<boolean> {
    //this.logger.info("authService => hasPermission");
    //this.logger.info(permission);
    //this.logger.info(this.user);
    return this.ensureUserInitialized().pipe(map(r => {
      if (this.hasRole("neglect-x.superadmin")) return true;

      return this.user?.Permissions.includes(permission) ?? false;
    }));
  }

  hasSuperadmin(): Observable<boolean> {
    return this.hasScreenResource("NX_SUPERADMIN", OperationType.READ);
  }

  getUser(): NXUser {
    return this.user;
  }

  refresh(): Observable<TokenResponse> {
    return from(this.oauthService.refreshToken());
  }

  logout(isForced: boolean): void {
    if (!this.router.isActive("/login", { paths: "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored" })) {
      from(this.router.navigateByUrl("/login")).subscribe(() => {
        if (!isForced) {
          this.messageService.showSnackBarWarning("U bent uitgelogd.");
        }
      })
    }

    this.storageService.remove("nx.user");

    if (!isForced) {
      this.oauthService.logOut();
    }
  }
}
