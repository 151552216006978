<div class="page-background-block"></div>
<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-title">Gebruikersbeheer Intergemeentelijke Samenwerking</div>
    <div fxLayoutGap="24px">
      <button mat-raised-button color="primary" (click)="updateUserRoles()">OPSLAAN</button>
    </div>
  </div>
</div>
<mat-card fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="main">
  <div fxFlex="33%" fxLayout="row" class="h-100">
    <div fxFlex="100">
      <div fxLayout="column">
        <div fxFlex="1 1 auto" class="generic-search-field" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>search</mat-icon>
          <input
            #input
            matInput
            [(ngModel)]="filterText"
            (keyup)="applyFilter()"
            placeholder="Zoek op naam of voornaam"
            class="search-input"
          />
          <button *ngIf="filterText != ''" mat-icon-button matTooltip="Zoekopdracht verwijderen" (click)="removeFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="column" class="users-table">
        <div class="table-responsive">
          <table fxFlexFill mat-table [dataSource]="appBeheersrechtenDataSource">
            <ng-container matColumnDef="Gebruiker">
              <th mat-header-cell *matHeaderCellDef>Gebruiker</th>
              <td mat-cell *matCellDef="let item">{{ item.Name }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="appBeheersrechtenColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [attr.colspan]="appBeheersrechtenColumns.length">Geen gebruikers.</td>
            </tr>
            <tr
              mat-row
              (click)="selectUnselectRow(row)"
              [class.demo-row-is-clicked]="clickedRow === row"
              *matRowDef="let row; columns: appBeheersrechtenColumns"
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="67%" fxLayout="row" class="h-100 ml-16 overflow-auto">
    <div *ngIf="clickedRow" fxLayout="column" fxFlex="100" class="mt-64">
      <div fxLayout="row">
        <mat-form-field fxLayout="row" fxFlex="100">
          <mat-label>E-mail</mat-label>
          <input matInput placeholder="Email" [(ngModel)]="clickedRow.Email" disabled />
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field fxLayout="row" fxFlex="100">
          <mat-label>Rijksregisternummer</mat-label>
          <input matInput placeholder="Rijksregisternummer" [(ngModel)]="clickedRow.NationalRegister" />
        </mat-form-field>
      </div>
      <ng-container *ngFor="let niscodeOfficialRoles of officials?.ListNiscodeOfficialRoles">
        <app-official-role [clickedRow]="clickedRow"  [niscodeOfficialRoles]="niscodeOfficialRoles" [roles]="roles"></app-official-role>
      </ng-container>
    </div>
  </div>
</mat-card>
